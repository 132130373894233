/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Stack
 * Type: Object
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$stack-gutter: $spacing-regular !default;
$stack-has-tiny: false !default;
$stack-has-small: false !default;
$stack-has-medium: false !default;
$stack-has-large: false !default;
$stack-has-huge: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-stack {
  --space: #{ rem($stack-gutter) };

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;

  > * {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: var(--space);
  }

  > *:first-child[hidden] + * {
    --space: 0;
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

@if ($stack-has-tiny) {
  .o-stack--tiny {
    --space: #{ rem($spacing-tiny) };
  }
}

@if ($stack-has-small) {
  .o-stack--small {
    --space: #{ rem($spacing-small) };
  }
}

@if ($stack-has-medium) {
  .o-stack--medium {
    @include fluid-size(20px, 30px, --space);
  }
}

@if ($stack-has-large) {
  .o-stack--large {
    --space: #{ rem($spacing-large) };
  }
}

@if ($stack-has-huge) {
  .o-stack--huge {
    @include fluid-size(30px, 50px, --space);
  }
}
