/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Media
 * Type: Object
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$media-gutter: $spacing-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-media {
  --gutter: #{ rem($media-gutter) };

  @include clearfix;
}

  .o-media__figure {
    margin-bottom: var(--gutter);
  }

  .o-media__content {
    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        margin-top: var(--gutter);
      }
    }
  }

    .o-media__button {}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($from: regular) {
  .o-media__figure {
    float: left;
    max-width: calc(50% + var(--gutter));
    padding-right: var(--gutter);
  }
}

/*******************************************************************************
 * Variants
*******************************************************************************/

/**
 * Right
*******************************************************************************/

.o-media--right {
  @include mq($from: regular) {
    .o-media__figure {
      order: 1;
      float: right;
      padding-right: 0;
      padding-left: var(--gutter);
    }
  }
}

/**
 * Columned
*******************************************************************************/

.o-media--columned {
  @include mq($from: regular) {
    display: flex;
    align-items: flex-start;

    .o-media__figure {
      flex: 0 0 auto;
      float: none;
      width: calc(50% + var(--gutter));
    }

    .o-media__content {
      flex: 1 1 auto;
      float: none;
    }

    &.o-media--right {
      justify-content: flex-end;
    }
  }
}

/**
 * Middle
*******************************************************************************/

.o-media--middle {
  @include mq($from: regular) {
    .o-media__content {
      align-self: center;
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .o-media {
    break-inside: avoid;
  }
}
