/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Header
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$header-navigation-background: var(--color-secondary-50) !default;
$header-cart-count: var(--color-secondary-800) !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-header {
  --gap: #{ rem($spacing-tiny) };

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $global-header-zindex;
  display: flex;
  flex-direction: column;
}

  .c-header__body {
    padding: rem(14px 0);
  }

    .c-header__headband {
      @if $namespace == "lme" {
        color: var(--color-foreground);
        background-color: var(--color-primary-light);
      } @else if $namespace == "kaizen" {
        --color-outline: var(--color-foreground-dark);
        color: var(--color-foreground-dark);
        background-color: var(--color-primary-dark);
      }
    }

    .c-header__top {
      width: 100%;
    }

    .c-header__main {}

      .c-header__wrapper {
        .c-header__top & {
          display: flex;
        }
      }

        .c-header__items {
          display: flex;
          align-items: center;
          padding: 0;
          margin: calc(-1 * var(--gap));
          list-style: none;

          .c-header__top & {
            padding-right: 1rem;

            &:last-child {
              padding-right: 0;
              margin-left: auto;
            }
          }
        }

          .c-header__item {
            padding: var(--gap);

            &--logo {
              flex-shrink: 1;
            }

            &--navigation {
              flex-grow: 1;
            }

            &--actions {
              text-align: right;
            }

            &--burger {
              line-height: 0;
            }
          }

            .c-header__link {
              --color-icon: inherit;

              max-width: rem(300px);
              padding: 0.25em;
            }

            .c-header__burger {}

            .c-header__close {
              color: var(--color-foreground-dark);
              background-color: var(--color-background-dark);
            }

            .c-header__home {
              display: inline-block;
              font-size: rem(16px);
            }

            .c-header__navigation {
              display: none;

              .no-js &,
              .c-header.is-mounted &:not([aria-hidden]) {
                display: block;
              }

              &[aria-hidden] {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1;
                width: calc(100% - #{ rem(54px) });
                max-width: rem(400px);
                height: 100vh;
                height: calc(100vh - var(--vh-offset));
                padding-top: rem(16px);
                overflow-x: hidden;
                overflow-y: auto;
                overscroll-behavior: contain;
                color: var(--color-foreground);
                background-color: $header-navigation-background;
                animation: header-navigation-show $animations-transition-duration $animations-timing-function;
                @include transition(opacity transform);
              }

              &.is-hiding {
                opacity: 0;
                transform: translate3d(#{ rem(-1 * $spacing-regular) }, 0, 0);
              }

              &[aria-hidden="false"] { display: block; }
              &[aria-hidden="true"] { display: none; }
            }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: large) {
  .c-header {
    flex-direction: column-reverse;
  }

    .c-header__body {
      display: flex;
      gap: rem(5px);
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding-right: rem(24px);
      padding-left: rem(24px);
    }

      .c-header__main {
        flex-basis: 20%;
        flex-grow: 1;
        padding-right: rem(10px);
      }

        .c-header__wrapper {
          --gutter: 0;
        }

        .c-header__items--desktop,
        .c-header__item--desktop {
          display: none;
        }

        .c-header__item--navigation {
          padding: 0;
        }

          @if $namespace == "lme" {
            .c-header__logo {
              width: rem(110px);
              height: rem(40px);
            }
          } @else if $namespace == "kaizen" {
            .c-header__logo {
              width: rem(110px);
              height: rem(30px);
            }
          }
}

@include mq($until: regular) {
  .c-header__main {
    flex-basis: 47%;
  }
}

@include mq($until: small) {
    .c-header__items {
      .c-header__top & {
        --gap: #{ rem(5px) };
      }
    }
}

@include mq($from: large) {
  .c-header__wrapper {
    position: relative;

    .c-header__main:not(:first-child) & {
      margin-top: 1rem;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: var(--gutter);
        left: var(--gutter);
        display: block;
        width: auto;
        border-top: var(--separator);
      }
    }
  }

    .c-header__item--burger,
    .c-header__burger {
      display: none;
    }

    @if $namespace == "lme" {
      .c-header__logo {
        width: rem(176px);
        height: rem(64px);
      }
    } @else if $namespace == "kaizen" {
      .c-header__logo {
        width: rem(140px);
        height: rem(38px);
      }
    }

    .c-header__item--navigation {
      position: relative;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        display: block;
        width: rem(24px);
        height: 100%;
        background: linear-gradient(to right, transparent, var(--color-background));
        pointer-events: none;
      }
    }

    .c-header__navigation {
      font-size: rem(18px);
    }
}

@include mq($from: huge) {
  .c-header__navigation {
    --gap: #{ $spacing-small };

    font-size: rem(20px);
  }

  .c-header__link {
    padding-right: 0.75em;
    padding-left: 0.75em;
  }
}

/*******************************************************************************
 * States
*******************************************************************************/

.c-header.has-navigation-active {
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(100vh - var(--vh-offset));
    cursor: pointer;
    background-color: var(--color-black);
    opacity: 0.85;
  }

  &:not(.has-navigation-hiding) .c-header__burger {
    position: fixed;
    top: rem(16px);
    right: rem(16px);
    z-index: 1;
    color: var(--color-white);
  }
}

.c-header.is-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--color-background);
  transform: translateY(-100%);

  &.is-pinned {
    box-shadow: rem(0 6px 20px) rgba(0, 0, 0, 0.15);
    transform: translateY(0);
    @include transition(transform box-shadow background-color);
  }

    .c-header__items--not-sticky {
      display: none;
    }

    @if $namespace == "lme" {
      .c-header__logo {
        width: rem(110px);
        height: rem(40px);
      }
    } @else if $namespace == "kaizen" {
      .c-header__logo {
        width: rem(110px);
        height: rem(30px);
      }
    }

  @include mq($from: large) {
    .c-header__top .c-header__wrapper {
      justify-content: space-between;

      :last-child {
        margin-left: 0;
      }
    }

    .c-header__main {
      display: none;
    }
  }
}

.c-header:not(.is-sticky) {
  .c-header__items--sticky {
    display: none;
  }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-header--overlay {
  position: relative;

  .c-header__item--burger,
  .c-header__burger {
    display: block;
  }

  .c-header__item--navigation {
    &::after {
      display: none;
    }
  }

  &.has-navigation-active:not(.has-navigation-hiding) {
    .c-header__burger {
      color: var(--color-foreground);
      background-color: var(--color-background);
    }
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes header-navigation-show {
  from {
    opacity: 0;
    transform: translate3d(#{ rem(-1 * $spacing-regular) }, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes header-notification-show {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-header {
    break-inside: avoid;
  }

    .c-header__item {
      &[data-cart-count]:not([data-cart-count=""]) {
        &::after {
          color: color("white") !important;
          background-color: color("black") !important;
        }
      }
    }
}
