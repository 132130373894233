/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form
 * Type: Element
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  float: left;
  width: 100%;

  + * {
    clear: left;
  }
}
