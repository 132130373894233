/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Hidden
 * Type: Utilities
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility

.u-hidden-visually {
  @include hidden-visually;
}

@include mq($until: large) {
  .u-hidden-visually\@until-large {
    @include hidden-visually;
  }
}

// Hide visually and from screen readers.

.u-hidden {
  display: none !important;
}
