/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Pastil
 * Type: Component
**/

@use "sass:math";

/**
 * Variables
*******************************************************************************/

$pastil-font-size: 72px;
$pastil-border-width: $global-border-width;
$pastil-primary: var(--color-secondary-500) !default;

/*******************************************************************************
* Default styles
*******************************************************************************/

.c-pastil {
  --foreground: inherit;
  --background: transparent;
  --border: currentcolor;

  @include appearance;
  position: relative;
  display: inline-block;
  align-items: center;
  padding: em(21px 19px, $pastil-font-size);
  font-family: var(--font-brand);
  // font-size: rem($pastil-font-size);
  font-weight: $font-bold;
  line-height: 0;
  text-align: center;
  text-decoration: none;
  color: var(--foreground);
  background: none var(--background);
  border: rem($pastil-border-width) solid var(--border);
  border-radius: em(72px, $pastil-font-size);
}

  .c-pastil__icon {
    margin: em(0 2px, 28px);
    font-size: em(28px, $pastil-font-size);
    vertical-align: top;
  }

  .c-pastil__label {
    display: inline-block;
    min-width: em(32px, 26px);
    margin: 0;
    font-size: em(26px, $pastil-font-size);
    line-height: 1;

    .c-pastil__icon ~ & {
      @include hidden-visually;
    }
  }

/*******************************************************************************
 * Variant
*******************************************************************************/

.c-pastil--primary {
  --border: #{ $pastil-primary };
}

.c-pastil--medium {
  @include fluid-size(46px, 56px);
}

.c-pastil--large {
  @include fluid-size(52px, 72px);
}

.c-pastil--fill {
  --foreground: var(--color-white);
  --background: var(--color-foreground);
  --border: var(--background);
}

.c-pastil--grade {
  padding: em(14px 12px, $pastil-font-size);
  font-family: $font-default;
  border-width: em(4px, $pastil-font-size);

  .c-pastil__icon {
    margin: em(0 2px, 34px);
    font-size: em(34px, $pastil-font-size);
  }

  .c-pastil__label {
    min-width: em(40px, 36px);
    font-size: em(36px, $pastil-font-size);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-pastil {
    break-inside: avoid;

    * {
      color: inherit !important;
    }
  }

  .c-pastil--fill {
    color: color("white") !important;
    background-color: color("black") !important;
  }
}
