/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Button
 * Type: Component
**/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

$button-font-size: $font-size-regular;
$button-border-width: $global-border-width;

/*******************************************************************************
* Default styles
*******************************************************************************/

.c-button {
  --color: inherit;
  --background: transparent;
  --border: var(--color-neutral-400);
  --color-hover: var(--color-primary-700);
  --background-hover: transparent;
  --border-hover: currentcolor;

  @include appearance;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: em(8px 11px, $button-font-size);
  font-size: rem($button-font-size);
  font-weight: $font-bold;
  line-height: math.div(22px, $button-font-size);
  text-align: center;
  text-decoration: none;
  color: var(--color);
  background: none var(--background);
  border: rem($button-border-width) solid var(--border);
  border-radius: em(4px, $button-font-size);

  &.c-button--disabled,
  &[aria-disabled="true"],
  &[disabled] {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;

    .c-button__icon {
      color: inherit;
    }
  }

  @include mq($until: small) {
    font-size: rem($font-size-small);
  }
}

  .c-button__icon {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
    margin: em(6px, 18px);
    font-size: em(18px, $button-font-size);
  }

  .c-button__label {
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    margin: em(4px, $button-font-size);
  }

/*******************************************************************************
 * Variant
*******************************************************************************/

/**
 * Reverse
*******************************************************************************/

.c-button--reverse {
  flex-direction: row-reverse;
}

/**
 * Fill
*******************************************************************************/

.c-button--fill {
  --color: var(--color-foreground-dark);
  --background: var(--color-primary-600);
  --border: transparent;
  --color-hover: var(--color-foreground-dark);
  --background-hover: var(--color-primary-700);
  --border-hover: transparent;
}

/**
 * Icon
*******************************************************************************/

.c-button--icon {
  padding: em(8px, $button-font-size);
  border-radius: rem(4px);

  .c-button__label {
    @include hidden-visually;
  }
}

.c-button--small {
  padding: em(3px, $font-size-small);
  font-size: rem($font-size-small);

  .c-button__icon {
    margin: em(4px, $font-size-regular);
    font-size: rem($font-size-regular);
  }

  .c-button__label {
    margin: em(2px, $font-size-small);
  }
}

/**
 * Shop
*******************************************************************************/

.c-button--shop {
  --color: inherit;
  --border: currentcolor;
  --color-hover: inherit;
  --border-hover: var(--color-shop-dark);

  &.c-button--fill {
    --color: var(--color-foreground-dark);
    --background: var(--color-shop-dark);
    --border: transparent;
    --color-hover: var(--color-black);
    --background-hover: var(--color-shop-light);
    --border-hover: transparent;
  }
}

/*******************************************************************************
 * States
*******************************************************************************/

/**
 * Interactive
*******************************************************************************/

a.c-button,
button.c-button {
  cursor: pointer;
  @include transition(color background-color border-color transform);

  &:hover {
    --color: var(--color-hover);
    --background: var(--background-hover);
    --border: var(--border-hover);

    text-decoration: none;
  }

  &:active {
    text-decoration: none;
    transform: translateY(#{ rem(1px) });
    transition-duration: 0s;
  }
}

/**
 * Feedback
*******************************************************************************/

.c-button[data-feedback] {
  &::after {
    content: attr(data-feedback);
    position: absolute;
    bottom: calc(100% + 0.25em);
    left: 50%;
    padding: 0.25em 0.5em;
    white-space: nowrap;
    color: var(--color-foreground-dark);
    background-color: var(--color-background-dark);
    border-radius: 0.25em;
    transform: translateX(-50%);
    animation: button-feedback-show $animations-transition-duration $animations-timing-function;
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes button-feedback-show {
  from {
    opacity: 0;
    transform: translate(-50%, 0.5em);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-button {
    break-inside: avoid;

    * {
      color: inherit !important;
    }
  }

  .c-button--fill {
    color: color("white") !important;
    background-color: color("black") !important;
  }
}
