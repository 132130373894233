/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Newspaper
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$newspaper-gap: 24px;
$newspaper-min: 300px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-newspaper {
  --gap: #{ rem($newspaper-gap) };
  --min: #{ rem($newspaper-min) };
}

  .o-newspaper__items {
    display: grid;
    padding: 0;
    margin: 0;
    list-style: none;
    grid-gap: var(--gap);

    @supports (width: min(var(--min), 100%)) {
      grid-template-columns: repeat(auto-fit, minmax(min(var(--min), 100%), 1fr));
    }
  }

    .o-newspaper__item {}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.o-newspaper--front {
  --gap: #{ rem(16px) };

  .o-newspaper__item {
    padding-top: var(--gap);
    border-top: var(--separator);

    &.o-newspaper__item--cover {
      --gap: 0;

      grid-column: 1 / -1;
      border-top: 0;
    }
  }

  @include mq($from: medium) {
    .o-newspaper__item.o-newspaper__item--cover {
      grid-area: 1 / 1 / -6 / -2;

      + .o-newspaper__item {
        padding-top: 0;
        border: 0;
      }
    }
  }

  @include mq($from: medium, $until: large) {
    .o-newspaper__items {
      @supports (width: min(var(--min), 100%)) {
        grid-template-columns: 2fr 1fr;
      }
    }
  }

  @include mq($from: large) {
    .o-newspaper__item:not(.o-newspaper__item--cover) {
      margin-left: var(--gap);
    }
  }
}

.o-newspaper--aside {
  .o-newspaper__item {}

  @include mq($from: large) {
    .o-newspaper__item {
      grid-column: span 2;

      &.o-newspaper__item--cover {
        grid-area: 1 / 3 / -4 / 3;
      }
    }
  }
}
