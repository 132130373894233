$icon-has-medium: true;

$pastil-primary: var(--color-primary);

$link-weight: $font-regular;
$link-color-icon: currentcolor;
$link-has-primary: true;
$link-has-toggler: true;

$figure-legend: var(--color-neutral-600);
$figure-has-full: true;
$figure-has-shadow: true;

$input-border-color: var(--color-neutral-400);
$option-border-color: var(--color-neutral-400);

$burger-has-outline: true;

$highlight-has-spaced: true;
$highlight-foreground: var(--color-foreground);
$highlight-background: var(--color-primary-light);
$highlight-variants: (
  "shop": (
    "foreground": "foreground",
    "background": "shop-light",
  )
);

$push-title-font: var(--font-brand);
$push-has-action: true;

$values-background: var(--color-primary-light);
$values-foreground: var(--color-foreground);

$header-navigation-background: var(--color-background);
$header-cart-count: var(--color-shop-800);

$footer-foreground: var(--color-foreground);
$footer-background: var(--color-background);
$footer-emphasis: var(--color-foreground);
$footer-emphasis-dark: var(--color-border);
$footer-has-separator: false;

@if $namespace == "lme" {
  $footer-foreground: var(--color-foreground);
  $footer-background: var(--color-background);
  $footer-emphasis: var(--color-foreground);
  $footer-has-separator: true;
} @else if $namespace == "kaizen" {
  $footer-foreground: var(--color-white);
  $footer-background: var(--color-black);
  $footer-emphasis: var(--color-white);
}
