/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Card
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$card-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-card {
  position: relative;
  font-size: rem($card-font-size);

  > * + * {
    margin-top: rem(16px);
  }
}

  .c-card__head {
    position: relative;
    line-height: 0;
  }

    .c-card__figure {
      line-height: 0;

      .c-card__head > .c-card__tags ~ & {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        }
      }
    }

  .c-card__body {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem(6px);
    }
  }

    .c-card__tags {
      color: var(--color-primary-dark);

      .c-card__title ~ & {
        color: var(--color-shop-700);
      }

      .c-card__head & {
        position: absolute;
        right: rem(24px);
        bottom: rem(20px);
        left: rem(24px);
        line-height: $global-line-height;
        color: var(--color-foreground-dark);
      }
    }

      .c-card__tag {
        position: relative;
        z-index: 1;
      }

      .c-card__separator {
        display: inline-block;
        width: em(4px, $card-font-size);
        height: em(4px, $card-font-size);
        vertical-align: middle;
        background-color: var(--color-primary-300);
        border-radius: 50%;
      }

    .c-card__title {
      @include h5;
      @include title($root: "card");

      font-family: var(--font-brand);
      font-weight: $font-bold;
    }

      .c-card__mainlink {
        text-decoration: none;
      }

    .c-card__metas {
      color: var(--color-neutral-600);
    }

    .c-card__richtext {
      display: block;
      display: -webkit-box;
      max-height: 3em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: var(--color-neutral-600);
    }

/*******************************************************************************
 * States
*******************************************************************************/

.c-card--interactive {
  cursor: pointer;

  .c-card__mainlink {
    @include transition(color text-decoration);
    text-decoration-color: transparent;
  }

  &:hover {
    .c-card__mainlink {
      text-decoration: underline;
      text-decoration-color: currentcolor;
      color: var(--color-shop-dark);
    }
  }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-card--landscape {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: rem(16px);

  > * + * {
    margin-top: 0;
  }

  .c-card__head {
    flex-basis: rem(282px);
    flex-grow: 1;
    max-width: 100%;
  }

  .c-card__body {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 52%;
  }
}

.c-card--emphasis {
  .c-card__title {
    @include h1;
  }

  .c-card__richtext {
    @include fluid-size(14px, 16px);

    max-height: 4.5em;
    -webkit-line-clamp: 3;
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-card {
    break-inside: avoid;
  }
}
