/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Burger
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/**
 * Variables
*******************************************************************************/

$burger-font-size: 14px;
$burger-line-width: 20px;
$burger-line-height: 2px;
$burger-padding: 4px;

$burger-has-outline: false !default;

 /**
 * Styles
*******************************************************************************/

.c-burger {
  --burger-lines-color: inherit;

  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  font: inherit;
  font-size: rem($burger-font-size);
  font-weight: $font-semibold;
  text-transform: uppercase;
  appearance: none;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border: 0;
}

  .c-burger__lines {
    position: relative;
    display: block;
    width: em($burger-line-width + $burger-padding * 2, $burger-font-size);
    height: em($burger-line-width + $burger-padding * 2, $burger-font-size);
    padding: em($burger-padding, $burger-font-size);
    line-height: 0;
    color: var(--burger-lines-color);
    border-radius: em($burger-line-width + $burger-padding, $burger-font-size);
  }

  .c-burger__line {
    position: absolute;
    top: 50%;
    left: em($burger-padding, $burger-font-size);
    width: em($burger-line-width, $burger-font-size);
    height: em($burger-line-height, $burger-font-size);
    margin-top: em(math.div(-$burger-line-height, 2), $burger-font-size);
    background-color: currentcolor;
    border-radius: em($burger-line-height, $burger-font-size);
    transform-origin: center center;
    @include transition(width color transform);

    &:first-child {
      transform: translateY(#{ em(-($burger-line-height + $burger-padding), $burger-font-size) });
    }

    &:nth-child(2) {
      transform: scaleX(0.7);
      transform-origin: left center;
    }

    &:nth-child(3) {
      transform: translateY(#{ em(($burger-line-height + $burger-padding), $burger-font-size) });
    }
  }

  .c-burger__label {
    @include hidden-visually;
  }

.c-burger[aria-expanded="false"] {
  &:hover {
    .c-burger__line {
      &:nth-child(2) {
        transform: scaleX(1);
      }
    }
  }
}

.c-burger[aria-expanded="true"],
.c-burger.is-expanded {
  @include transition(transform);

  .c-burger__line {
    left: em($burger-padding - 1, $burger-font-size);
    transform: scaleX(0);

    &:first-child {
      transform:
        rotate(45deg)
        translateX(#{em(1px, $burger-font-size)})
        translateY(#{em(-1px, $burger-font-size)})
        scaleX(1.2);
    }

    &:nth-child(3) {
      transform:
        rotate(-45deg)
        translateX(#{em(1px, $burger-font-size)})
        translateY(#{em(1px, $burger-font-size)})
        scaleX(1.2);
    }
  }

  &:hover {
    transform: rotate(0.25turn);
  }
}

/**
 * Modifiers
*******************************************************************************/

@if $burger-has-outline {
  .c-burger--outline {
    padding: em(9px, $burger-font-size);
    border: em(1px, $burger-font-size) solid var(--color-border);
    border-radius: 50%;
  }
}

/**
 * Print
*******************************************************************************/

@media print {
  .c-burger {
    break-inside: avoid;
  }

    .c-burger__line {
      background-color: currentcolor !important;
    }
}
