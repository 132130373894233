/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$ripple-duration: 1.5s;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form {
  position: relative;

  &.is-loading {
    pointer-events: none;

    > * {
      opacity: 0.25;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem(40px);
      height: rem(40px);
      background-color: var(--color-primary-500);
      border-radius: 50%;
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      animation: ripple-effect $ripple-duration cubic-bezier(0, 0.2, 0.8, 1) infinite;
      will-change: transform;
    }

    &::before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(0.5);
    }

    &::after {
      opacity: 0.5;
      transform: translate(-50%, -50%) scale(1);
      animation-delay: -$ripple-duration * 0.5;
    }
  }
}

  .c-form__fields {
    --gap: #{ rem($spacing-regular) };

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: calc(-1 * var(--gap) / 2);

    > * {
      padding: calc(var(--gap) / 2);

      @include mq($from: small) {
        width: auto;
      }
    }
  }

/**
 * Animations
 **********************************************************************************/

 @keyframes ripple-effect {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5);
  }
}
