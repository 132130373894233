/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Values
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$values-background: var(--color-primary-800) !default;
$values-foreground: var(--color-foreground-dark) !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-values {
  @include fluid-size($blocks-gutter-start, $blocks-gutter-end, padding);
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  color: $values-foreground;
  background-color: $values-background;
}

  .c-values__wrapper {
    > * {
      margin: 0;
    }

    > * + * {
      @include fluid-size(40px, 50px, margin-top);
    }
  }

    .c-values__head {}

      .c-values__title {
        @include h3;

        margin: 0;
        font-family: var(--font-brand);
      }

    .c-values__body {}

      .c-values__items {
        --min: #{ rem(180px) };
      }

        .c-values__item {
          max-width: 50%;
        }

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-values__item {
    break-inside: avoid;
  }
}
