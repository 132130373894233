/*******************************************************************************
 * Infos
*******************************************************************************/

@use "sass:math";

/**
 * Name: Widths
 * Type: Utilities
 *
 * generates a series of utility classes that give a fluid width to
 * whichever element they’re applied, e.g.:
 *
 *   <img src="" alt="" class="u-1/2" />
 *
 * These classes are most commonly used in conjunction with our layout system,
 * e.g.:
 *
 *   <div class="o-layout__item  u-1/2">
 *
 * By default, it will also generate responsive variants of each of these
 * classes by using your Sass MQ configuration, e.g.:
 *
 *   <div class="o-layout__item  u-1/1  u-1/2@tablet  u-1/3@desktop">
 */

// Which fractions would you like in your grid system(s)? By default, it
// provides you fractions of one whole, halves, thirds, quarters and fifths,
// e.g.:
//   .u-1/2
//   .u-2/5
//   .u-3/4
//   .u-2/3

/*******************************************************************************
 * Utilites
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Variables
*******************************************************************************/

$widths-fractions: 1 2 3 4 5 6;

// By default, it uses fractions-like classes like `<div class="u-1/4">`.
// You can change the `/` to whatever you fancy with this variable.
$widths-delimiter: \/;

// This defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
// <div class="u-3/12@mobile">
$widths-breakpoint-separator: \@;

// Use Sass-MQ breakpoints by default
$widths-breakpoints: ();

@if map.has-key($mq-breakpoints, "regular") {
  $widths-breakpoints: map.set($widths-breakpoints, "regular", map.get($mq-breakpoints, "regular"));
}

@if map.has-key($mq-breakpoints, "medium") {
  $widths-breakpoints: map.set($widths-breakpoints, "medium", map.get($mq-breakpoints, "medium"));
}

/*******************************************************************************
 * Mixins
*******************************************************************************/

// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
// @include widths(3 4, -sm);

@mixin widths($columns, $breakpoint: null) {

  // Loop through the number of columns for each denominator of our fractions.
  @each $denominator in $columns {

    // Begin creating a numerator for our fraction up until we hit the
    // denominator.
    @for $numerator from 1 through $denominator {

      // Build a class in the format `.u-3/4[@<breakpoint>]`.

      .u-#{$numerator}#{$widths-delimiter}#{$denominator}#{$breakpoint} {
        width: math.div($numerator, $denominator) * 100% !important;
      }
    }
  }
}

.u-width-auto {
  width: auto !important;
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * A series of width helper classes that you can use to size things like grid
 * systems. Classes take a fraction-like format (e.g. `.u-2/3`). Use these in
 * your markup:
 *
 * <div class="u-7/12">
 *
 * The following will generate widths helper classes based on the fractions
 * defined in the `$widths-fractions` list.
 */

@include widths($widths-fractions);

/**
 * Automatically generate grid system(s) for each of our
 * defined breakpoints, and give them a Responsive Suffix, e.g.:
 *
 * <div class="u-3/12@mobile">
 */

@each $bp-name, $bp-value in $widths-breakpoints {
  @include mq($from: $bp-name) {
    @include widths($widths-fractions, #{$widths-breakpoint-separator}#{$bp-name});
  }
}
