/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Navigation
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$navigation-font-size: 18px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-navigation {
  --gap: #{ rem($spacing-tiny) };

  position: relative;
  font-size: rem($navigation-font-size);
}

  .c-navigation__burger {}

  .c-navigation__body {}

    .c-navigation__items {
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;

      .c-navigation__foot & {
        border-top: var(--separator);
      }
    }

      .c-navigation__item {
        &--separator {
          display: flex;
          align-items: center;
          padding: rem($spacing-tiny) 0;

          &::before {
            content: "";
            display: inline-block;
            width: rem(4px);
            height: rem(4px);
            background-color: currentcolor;
            border-radius: rem(4px);
          }
        }
      }

        .c-navigation__link,
        .c-navigation__control {
          @include transition(color);

          &:hover {
            color: var(--color-primary-dark);
          }
        }

        .c-navigation__link {
          padding: rem($spacing-tiny) 0;
        }

        .c-navigation__control {
          @include appearance;
          text-decoration: none;
          white-space: nowrap;
          cursor: pointer;

          &:hover,
          &--current {
            color: var(--color-primary-dark);
          }
        }

  .c-navigation__foot {}

/*******************************************************************************
 * States
*******************************************************************************/

/**
 * Vertical
*******************************************************************************/

.c-navigation[aria-hidden] {
  .c-navigation__burger {
    position: absolute;
    left: calc(100% + rem(10px));
  }

  .c-navigation__foot {
    padding-bottom: rem(16px);
    font-size: rem($font-size-small);
  }

    .c-navigation__link {}

    .c-navigation__items {
      flex-direction: column;
      margin: 0 rem(30px);
    }

    .c-navigation__foot .c-navigation__items {
      padding-top: rem(16px);
      margin-top: rem(16px);
    }

      .c-navigation__item {
        &--desktop {
          display: none;
        }
      }

        .c-navigation__control {
          position: relative;
          display: block;
          width: 100%;
          padding: rem($spacing-tiny) 0;
          font-family: var(--font-brand);
          font-weight: $font-bold;
        }

          .c-navigation__control[aria-expanded] .c-navigation__marker {
            --rotation: 0;

            width: 1em;
            height: 1em;
            line-height: 0;

            &::before,
            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              display: block;
              width: em(16px, 24px);
              height: em(2px, 24px);
              background-color: currentcolor;
              border-radius: em(2px, 24px);
              transform: translate(-50%, -50%) rotate(var(--rotation));
              @include transition(transform);
            }

            &::after {
              --rotation: 0.25turn;
            }
          }

          .c-navigation__control[aria-expanded="true"] .c-navigation__marker {
            --rotation: 0.5turn;

            &::after {
              --rotation: 0.5turn;
            }
          }

        .c-navigation__menu {
          padding: rem(0 16px 20px);
        }

          .c-navigation__subitems {}

            .c-navigation__subitem {}

              .c-navigation__sublink {
                color: var(--color-primary-700);
              }

        .c-navigation__foot .c-navigation__button {
          margin-top: 0.5rem;
        }
}

/**
 * Horizontal
*******************************************************************************/

.c-navigation:not([aria-hidden]) {
  .c-navigation__burger,
  .c-navigation__foot {
    display: none;
  }

    .c-navigation__items {
      flex-direction: row;
      align-items: center;
    }

      .c-navigation__item {
        position: relative;
        flex: 1 0 auto;

        &--separator {
          justify-content: center;
        }

        &--mobile {
          display: none;
        }
      }

      .c-navigation__control {
        display: block;
        padding: rem(8px 12px);
        font-family: var(--font-brand);
        font-size: rem(18px);
        font-weight: $font-bold;
        text-align: center;

        &::after {
          content: "";
          position: absolute;
          right: var(--gap);
          bottom: 0;
          left: var(--gap);
          height: rem(2px);
          background-color: var(--color-secondary-700);
          opacity: 0;
          @include transition(opacity);
        }

        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }

      .c-navigation__button {
        --border: transparent;
      }

      .c-navigation__menu {
        position: absolute;
        top: 100%;
        left: 0;
        padding: rem(10px 24px);
        font-size: rem($navigation-font-size);
        color: var(--color-white);
        background-color: var(--color-primary-800);
      }

      .c-navigation__item:nth-last-child(-n+2) .c-navigation__menu {
        right: 0;
        left: auto;
      }

        .c-navigation__sublink {
          white-space: nowrap;
        }

          .c-navigation__marker {
            display: none;
          }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes navigation-menu-show {
  from {
    opacity: 0;
    transform: translate3d(0, #{ rem(-1 * $spacing-regular) }, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-navigation {
    break-inside: avoid;
  }
}
