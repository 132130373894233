/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Placeholder
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-placeholder {
  --ratio: 1;

  position: relative;
  display: block;
  background-color: var(--color-neutral-100);

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: calc(100% / var(--ratio));
  }
}

  .c-placeholder__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: auto;
    filter: grayscale(1);
    opacity: 0.4;
    transform: translate(-50%, -50%);
  }
