/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Alert
 * Type: Component
 * Description: Display an informative message.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$alert-themes: (
  "neutral": (
    "background": #f9fafb,
    "accent-color": #000,
  ),
  "success": (
    "background": #edfbf1,
    "accent-color": #2cb753,
  ),
  "warning": (
    "background": #fff6e9,
    "accent-color": #fbaa30,
  ),
  "error": (
    "background": #fbecec,
    "accent-color": #de332e,
  ),
);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-alert {
  --alert-color: var(--color-black);
  --alert-background: #f1f4ff;
  --alert-accent-color: #2e55ff;

  position: static;
  display: flex;
  padding: rem(16px);
  font-size: rem(14px);
  color: var(--alert-color);
  background: var(--alert-background);
  border-left: rem(6px) solid var(--alert-accent-color);
  border-radius: rem(4px);
  box-shadow: rem(0 4px 16px -4px) rgba(3, 0, 138, 0.2);
}

  .c-alert__icon {
    flex: 0 0 auto;
    margin-top: rem(2px);
    margin-right: rem(8px);
    font-size: rem(18px);
    color: var(--alert-accent-color);
  }

  .c-alert__content {
    --alert-content-spacing: #{rem(8px)};

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: var(--alert-content-spacing);
    }
  }

    .c-alert__title {
      font-weight: $font-bold;
    }

    .c-alert__richtext {}

/*******************************************************************************
 * Type modifiers
*******************************************************************************/

@each $type, $theme in $alert-themes {
  .c-alert--#{$type} {
    @each $key, $value in $theme {
      --alert-#{$key}: #{$value};
    }
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-alert {
    break-inside: avoid;
    border: rem(1px) solid color("black");
    border-left-width: rem(6px);
  }
}
