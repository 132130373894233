/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Clearfix
 * Type: Tool
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

// Mixin to drop micro clearfix into a selector. Further reading:
// @link http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
// .usage {
//   @include clearfix();
// }

@mixin clearfix() {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
