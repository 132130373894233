/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Headings
 * Type: Utilities
 *
 * Description:
 *   Redefine all of our basic heading styles against utility classes so as to
 *   allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 *   but we want it to be sized like an H1:
 *
 *     <h2 class="u-h1"></h2>
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.u-h-xxl {
  @include h-xxl;
}

// .u-h1 {
//   @include h1;
// }

.u-h2 {
  @include h2;
}

.u-h3 {
  @include h3;
}

.u-h4 {
  @include h4;
}

.u-h5 {
  @include h5;
}

.u-h6 {
  @include h6;
}
