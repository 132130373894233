/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Root
 * Type: Generic
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  @each $name, $value in $colors {
    --color-#{ $name }: #{ $value };
  }

  --font-brand: #{ $font-brand };
  --shadow: rgba(0, 0, 0, 0.15);
  --separator: #{ $global-separator };
}

@-ms-viewport { width: device-width; }
