/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Headline
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-headline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: rem($spacing-regular);
  padding-bottom: rem($spacing-regular);
  border-bottom: var(--separator);
}

  .c-headline__main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 55%;

    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        margin-top: rem(4px);
      }
    }
  }

    .c-headline__title {
      @include h3;
      margin: 0;
      font-family: var(--font-brand);
      font-weight: $font-bold;
    }

  .c-headline__action {
    flex-basis: auto;
    flex-grow: 1;
    width: auto;
    max-width: 100%;
  }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

.c-headline--small {
  gap: 1rem;

  .c-headline__title {
    @include h4;
  }
}
