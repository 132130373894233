/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Multiselect
 * Type: Component
 * Description: Based on Adrian Roselli article: https://adrianroselli.com/2022/05/under-engineered-multi-selects.html
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@import "common/components/form/form-share";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-multiselect {
  --input-foreground: #{$input-foreground};
  --input-background: #{$input-background};
  --input-border-color: #{$input-border-color};

  position: relative;
  z-index: 2;
}

  .c-form-multiselect__control {
    @include appearance;
    width: 100%;
    outline: 0;
  }

    .c-form-multiselect__label {
      display: block;
      color: var(--color-black);
      @include transition(color);

      .c-form-multiselect:focus-within & {
        color: var(--color-primary-800);
      }
    }

    .c-form-multiselect__input {
      display: block;

      &:not(:first-child) {
        margin-top: rem(4px);
      }
    }

      .c-form-multiselect__input-control {
        display: block;
        @include transition(background border box-shadow);

        .c-form-multiselect__control:focus & {
          --input-border-color: transparent;
          --input-outline-color: var(--color-outline);

          @include input-outline;
        }
      }

        .c-form-multiselect__input-counter {}

        .c-form-multiselect__input-placeholder {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $input-placeholder-color;

          .c-form-multiselect__input-counter:not(:empty) ~ & {
            display: none;
          }
        }

  .c-form-multiselect__menu {
    display: none;
    margin-top: 0.5rem;
    overflow: hidden;
    color: var(--input-foreground);
    background-color: var(--input-background);
    border: rem(1px) solid var(--input-border);
    border-radius: 0.5rem;

    .no-js &,
    .c-form-multiselect.is-mounted .c-form-multiselect__control[aria-expanded="true"] ~ & {
      display: block;
    }

    .c-form-multiselect__control[aria-expanded] ~ & {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
    }
  }

    .c-form-multiselect__items {
      max-height: rem(240px);
      padding: 0.25rem 1rem;
      overflow-y: auto;
    }

      .c-form-multiselect__skip {}

      .c-form-multiselect__item {
        padding: rem(10px 0);

        & + & {
          border-top: rem(1px) solid var(--color-border);
        }
      }

        .c-form-multiselect__input {}

        .c-form-multiselect__label {}
