/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Cluster
 * Type: Object
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$cluster-gutter: $spacing-regular;
$cluster-has-stretch: false !important;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-cluster {
  --space: #{ rem($cluster-gutter) };
}

  .o-cluster__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: calc(-0.5 * var(--space));
    list-style: none;
  }

    .o-cluster__item {
      display: block;
      padding: calc(0.5 * var(--space));
      margin: 0;

      &.o-cluster__item--fluid {
        flex-grow: 1;
      }
    }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

// .o-cluster--reverse {
//   .o-cluster__items {
//     flex-direction: row-reverse;
//     justify-content: flex-end;
//   }
// }

.o-cluster--center {
  > .o-cluster__items {
    justify-content: center;
  }
}

.o-cluster--middle {
  > .o-cluster__items {
    align-items: center;
  }
}

// .o-cluster--bottom {
//   .o-cluster__items {
//     align-items: flex-end;
//   }
// }

.o-cluster--fluid {
  > .o-cluster__items > .o-cluster__item {
    flex-grow: 1;
  }
}

.o-cluster--nowrap {
  > .o-cluster__items {
    flex-wrap: nowrap;
  }
}

@if ($cluster-has-stretch) {
  .o-cluster--stretch {
    .o-cluster__item {
      > * {
        height: 100%;
      }
    }
  }
}
