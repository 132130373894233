/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Sides
 * Type: Object
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$sides-min-width: 320px !default;
$sides-min-ratio: 50% !default;
$sides-gap: $spacing-large !default;
$sides-has-small: false !default;
$sides-has-fluid: false !default;
$sides-has-center: false !default;
$sides-has-align: false !default;
$sides-has-bottom: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-sides {
  --min-width: #{ rem($sides-min-width) };
  --min-ratio: #{ $sides-min-ratio };
  --gap: #{ rem($sides-gap) };

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--gap);
}

  .o-sides__bar {
    flex-basis: var(--min-width);
    flex-grow: 1;
    width: var(--min-width);
    max-width: 100%;
  }

  .o-sides__main {
    flex-basis: 0;
    flex-grow: 999;
    min-width: var(--min-ratio);
  }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

@if ($sides-has-small) {
  .o-sides--small {
    --gap: #{ rem($spacing-small) };
  }
}

@if ($sides-has-fluid) {
  .o-sides--fluid {
    --min-width: auto;
    --min-ratio: auto;

    .o-sides__main,
    .o-sides__bar {
      flex-shrink: 0;
    }
  }
}

@if ($sides-has-center) {
  .o-sides--center {
    align-items: center;
  }
}

@if ($sides-has-align) {
  .o-sides--align {
    .o-sides__main {
      align-self: center;
    }
  }
}

@if ($sides-has-bottom) {
  .o-sides--bottom {
    align-items: flex-end;
  }
}
