/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Typography
 * Type: Element
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

a {
  text-decoration: underline;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

strong {
  font-weight: $font-bold;
}

hr {
  margin: 2em 0;
  color: inherit;
  border-top: 1px dashed currentcolor;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  a {
    text-decoration: none;
    color: #000 !important;
  }

  p {
    orphans: 3;
    widows: 3;
  }
}
