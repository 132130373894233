/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Hidden
 * Type: Generic
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

[hidden] {
  display: none !important;
}
