/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Coupon
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$coupon-foreground: var(--color-foreground);
$coupon-background: var(--color-shop-100);
$coupon-emphasis: var(--color-shop-dark);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-coupon {
  --coupon-background: #{ $coupon-background };
  --coupon-foreground: #{ $coupon-foreground };
  --coupon-emphasis: #{ $coupon-emphasis };

  @include fluid-size($font-size-small, $font-size-regular);
  display: flex;
  gap: rem(6px);
  color: var(--coupon-foreground);
}

  .c-coupon__aside,
  .c-coupon__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em 0.75em;
    background-color: var(--coupon-background);

    &:first-child {
      padding-left: 1em;
      border-radius: 0.5em 0 0 0.5em;
    }

    &:last-child {
      padding-right: 1em;
      border-radius: 0 0.5em 0.5em 0;
    }
  }

  .c-coupon__main {
    flex: 1 1 auto;
  }

    .c-coupon__label {
      margin: 0;
    }

  .c-coupon__aside {
    position: relative;
    flex: 0 0 auto;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: rem(-6px);
      width: rem(6px);
      background-image: radial-gradient(circle at #{rem(3px 6px) }, transparent #{ rem(3px) }, var(--coupon-background, var(--color-background)) #{ rem(3px) }, var(--coupon-background, var(--color-background)) 0);
      background-position: 50% calc(50% - #{ rem(6px) });
      background-size: 100% rem(10px);
      background-repeat-x: no-repeat;
    }
  }

    .c-coupon__emphasis {
      @include fluid-size(20px, 30px);
      min-width: em(80px, 30px);
      font-weight: $font-bold;
      text-align: center;
      color: var(--coupon-emphasis);
    }
