/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Colors
 * Type: Utilities
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Variables
*******************************************************************************/

$colors-properties: (
  // "border": "border-color",
  "background": "background-color",
  "color": "color",
);

/*******************************************************************************
 * Default styles
*******************************************************************************/

@each $selector, $property in $colors-properties {
  [style*="--#{$selector}:"] {
    #{$property}: var(--#{$selector}) !important;
  }
}

/*******************************************************************************
 * Gradient
*******************************************************************************/

[style*="--gradient:"] {
  background-image: linear-gradient(to bottom, var(--gradient), transparent) !important;
}

/*******************************************************************************
 * Split
*******************************************************************************/

[style*="--split:"] {
  background-image: linear-gradient(to bottom, var(--split) 0%, var(--split) var(--split-limit, 50%), transparent var(--split-limit, 50%), transparent 100%) !important;
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  @if map.get($colors-properties, "background") {
    [style*="--background:"] {
      background-color: color("white") !important;
    }
  }

  @if map.get($colors-properties, "color") {
    [style*="--color:"] {
      color: color("black") !important;
    }
  }

  [style*="--gradient:"],
  [style*="--split:"] {
    background-image: none !important;
  }
}
