/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Option
 * Type: Component
 * Description: A single option respresented as a checkbox or radio button.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@import "common/components/form/form-share";

$option-size: 18px;
$option-gap: 8px;
$option-accent-color: var(--color-outline);
$option-accent-contrast-color: #{$input-background};
$option-border-size: #{$input-border-size};
$option-border-color: #{$input-border-color} !default;
$option-disabled-background-color: var(--color-neutral-100);
$option-disabled-label-color: var(--color-neutral-500);
$option-focus-outline-size: 2px;
$option-top-alignment: 2px;
$option-radio-checked-offset: 3px;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-option {
  --option-border-color: #{$option-border-color};
  --option-background-color: #{$option-accent-contrast-color};
  --option-color: inherit;
  --option-label-color: var(--color-black);

  display: flex;
  width: 100%;
}

  .c-form-option__control {
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
  }

    .c-form-option__doppel {
      display: block;
      width: rem($option-size);
      height: rem($option-size);
      margin-top: rem($option-top-alignment);
    }

    .c-form-option__input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    .c-form-option__doppel {
      position: relative;
      color: var(--option-color);
      background: var(--option-background-color);
      border: rem($option-border-size) solid var(--option-border-color);
      @include transition(background box-shadow color);

      .c-form-option__input:focus ~ & {
        box-shadow:
          0 0 0 rem($option-focus-outline-size $option-accent-contrast-color),
          0 0 0 rem(($option-focus-outline-size * 2) $option-accent-color);
      }

      .c-form-option__input:checked ~ & {
        --option-border-color: #{$option-accent-color};
        --option-background-color: #{$option-accent-color};
      }
    }

      .c-form-option__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        @include transition(transform);

        .c-form-option__input:not(:checked) ~ .c-form-option__doppel & {
          transform: translate(-50%, -50%) scale(0);
        }
      }

  .c-form-option__details {
    flex: 1 1 auto;
    margin-left: rem($option-gap);
    font-size: rem(14px);
  }

    .c-form-option__label {
      display: block;
      color: var(--option-label-color);
    }

/**
 * Checkbox
 ******************************************************************************/

.c-form-option--checkbox {
  .c-form-option__doppel {
    border-radius: rem(2px);
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    --option-color: #{$option-accent-contrast-color};
  }
}

/**
 * Radio
 ******************************************************************************/

.c-form-option--radio {
  .c-form-option__doppel {
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      top: rem($option-radio-checked-offset);
      right: rem($option-radio-checked-offset);
      bottom: rem($option-radio-checked-offset);
      left: rem($option-radio-checked-offset);
      background: $option-accent-contrast-color;
      border-radius: 50%;
      transform: scale(0);
      @include transition(transform);
    }
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    &::before {
      transform: scale(1);
    }
  }
}

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-option--disabled {
  --option-background-color: #{$option-disabled-background-color};
  --option-label-color: #{$option-disabled-label-color};

  .c-form-option__input,
  .c-form-option__doppel {
    cursor: not-allowed;
  }
}

.c-form-option--error {
  --option-background-color: var(--color-error-50);

  .c-form-option__input:not(:checked) ~ .c-form-option__doppel {
    --option-border-color: var(--color-error-500);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-form-option__doppel::before {
    background-color: currentcolor !important;
  }
}
