/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Event
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$event-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-event {
  @include fluid-size(16px, 24px, --event-spacing);

  position: relative;
  font-size: rem($card-font-size);
  border: var(--separator);
  border-radius: rem(8px);
}

  .c-event__main,
  .c-event__aside {
    padding: var(--event-spacing);
  }

  .c-event__main {
    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        margin-top: rem(8px);
      }
    }
  }

    .c-event__tags {
      color: var(--color-primary-dark);

      .c-event__title ~ & {
        color: var(--color-shop-700);
      }
    }

      .c-event__tag {
        position: relative;
        z-index: 1;
      }

      .c-event__separator {
        display: inline-block;
        width: em(4px, $card-font-size);
        height: em(4px, $card-font-size);
        vertical-align: middle;
        background-color: var(--color-primary-300);
        border-radius: 50%;
      }

    .c-event__title {
      @include h5;
      @include title($root: "event");

      font-family: var(--font-brand);
      font-weight: $font-bold;
    }

      .c-event__mainlink {
        text-decoration: none;
      }

    .c-event__richtext {
      display: block;
      display: -webkit-box;
      max-height: 6em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      color: var(--color-neutral-600);
    }

  .c-event__aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0;
    color: var(--color-neutral-600);
  }

    .c-event__metas {
      padding-top: var(--event-spacing);
    }

    .c-event__action {
      text-align: right;
    }

      .c-event__link {
        --color-icon: var(--color-primary-dark);

        font-weight: $font-bold;
      }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: regular) {
  .c-event {}

      .c-event__metas {
        border-top: var(--separator);
      }
}

@include mq($from: regular) {
  .c-event {
    display: flex;
  }

    .c-event__main {
      flex: 1 1 auto;
    }

    .c-event__aside {
      flex: 0 0 auto;
      width: rem(320px);
      max-width: 50%;
      padding-left: 0;
    }

      .c-event__metas {
        padding-left: var(--event-spacing);
        border-left: var(--separator);
      }
}
