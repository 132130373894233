/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Richtext
 * Type: Scope
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$richtext-font-size: $font-size-regular;
$richtext-line-height: $global-line-height;
$richtext-has-color: false !default;
$richtext-has-fluid: false !default;
$richtext-has-unlist: false !default;
$richtext-has-legacy: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.s-richtext {
  --vertical-space: 1.5em;
  --color: inherit;

  // Global spacings

  > * {
    margin-top: 0;
    margin-bottom: 0;

    + * {
      margin-top: var(--vertical-space);
    }
  }

  // Headings

  h1,
  .u-h1,
  h2,
  .u-h2,
  h3,
  .u-h3,
  h4,
  .u-h4,
  h5,
  .u-h5,
  h6,
  .u-h6 {
    font-family: var(--font-brand);
    font-weight: $font-bold;
  }

  h1 + h2,
  h2 + h3,
  h3 + h4,
  h4 + h5,
  h5 + h6 {
    margin-top: 0.25em;
  }

  // Inlines

  strong,
  b {
    font-weight: $font-bold;
  }

  small {
    font-size: 0.875em;
  }

  // Links

  a {
    text-decoration: underline;
    color: var(--color);
    transition: color $animations-transition-duration;

    &:hover {
      text-decoration: none;
    }
  }

  // Lists

  ul,
  ol {
    padding-left: 2em;

    > * {
      margin-top: 0;

      + * {
        margin-top: $richtext-line-height * 0.5em;
      }
    }

    ul,
    ol {
      margin-top: $richtext-line-height * 0.5em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal-leading-zero;
  }

    li::marker {
      font-weight: $font-black;
    }

  // Blockquote

  blockquote {
    @include h3;
    padding: em(24px, $font-h3-max);
    margin-right: 0;
    margin-left: 0;
    font-family: var(--font-brand);
    font-weight: $font-bold;
    color: var(--color);
    border-top: rem(4px) solid;
    border-bottom: rem(4px) solid;

    > * {
      margin-top: 0;
      margin-bottom: 0;

      + * {
        margin-top: $richtext-line-height * 1em;
      }
    }
  }

  hr {
    border: 0;
    border-top: var(--separator);
  }
}

@if $richtext-has-color {
  .s-richtext--color {
    --color: var(--color-primary-600);
  }
}

@if $richtext-has-fluid {
  .s-richtext--fluid {
    @include content;
  }
}

@if $richtext-has-unlist {
  .s-richtext--unlist {
    ol,
    ul {
      padding-left: 1.5em;
      list-style: none;
    }
  }
}

@if $richtext-has-legacy {
  .s-richtext--legacy {
    > div {
      margin: 1.5em 0;
    }

    > img {
      display: block;
      min-height: 200px;
      margin: 1.5em auto;
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .s-richtext {
    a[href]::after {
      content: " [" attr(href) "]";
      font-style: italic;
      opacity: 0.5;
    }

    abbr[title]::after {
      content: " [" attr(title) "]";
      font-style: italic;
      opacity: 0.5;
    }
  }
}
