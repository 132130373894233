/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Label
 * Type: Component
 * Description:
 *  A label to paired with an input control to indicates what we want from the
 *  user.
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-label {
  font-size: rem(14px);
}

  .c-form-label__indic {
    font-style: italic;
    color: var(--color-neutral-500);
  }
