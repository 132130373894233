/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Input
 * Type: Component
 * Description: A simple text input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@import "common/components/form/form-share";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-input {
  --input-height: #{rem($input-height)};
  --input-foreground: #{$input-foreground};
  --input-background: #{$input-background};
  --input-border-color: #{$input-border-color};
  position: relative;
}

  .c-form-input__control {
    width: 100%;
    height: var(--input-height);
    padding: rem($input-padding-y - $input-border-size) rem($input-padding-x - $input-border-size);
    font: inherit;
    appearance: none;
    color: var(--input-foreground);
    background: var(--input-background);
    border: rem($input-border-size) solid;
    border-color: var(--input-border-color);
    border-radius: rem($input-border-radius);
    @include transition(background border box-shadow);

    &::placeholder {
      color: $input-placeholder-color;
    }

    &[readonly],
    &[disabled] {
      cursor: not-allowed;
      background-color: var(--color-neutral-200);
      border-color: var(--color-neutral-400);
    }

    &:focus {
      --input-border-color: transparent;
      --input-outline-color: var(--color-outline);
      outline: 0;

      @include input-outline;
    }

    &[type="password"] {
      &::-ms-reveal {
        display: none;
      }

      .no-js & ~ [data-form-password-toggler] {
        display: none;
      }
    }
  }

/**
 * File
 ******************************************************************************/

.c-form-input--file {
  .c-form-input__control[type="file"] {
    font-size: revert;
  }
}

/**
 * Textarea
 ******************************************************************************/

.c-form-input--textarea {
  --input-height: #{rem(120px)};
}

/**
 * Select
 ******************************************************************************/

.c-form-input--select {
  .c-form-input__control:not([multiple]) {
    padding-right: rem($input-padding-x * 2 + $input-icon-size);
    background-image: icon($input-select-icon, black);
    background-repeat: no-repeat;
    background-position: right rem($input-padding-x) top 50%;
    background-size: rem($input-icon-size $input-icon-size);
  }

  .c-form-input__control[multiple] {
    --input-height: #{rem(120px)};
  }
}

/**
 * Small
 ******************************************************************************/

.c-form-input--small {
  --input-height: #{ rem(40px) };

  .c-form-input__control {
    padding: rem(8px - $input-border-size) rem(12px - $input-border-size);
  }

  &.c-form-input--select {
    .c-form-input__control:not([multiple]) {
      padding-right: rem(12px * 2 + $input-icon-size);
    }
  }
}

/*******************************************************************************
 * With action
*******************************************************************************/

@if $input-action-enable {
  .c-form-input--with-action {
    .c-form-input__control {
      padding-right: rem($input-padding-x * 2 + $input-action-size);
    }
  }

    .c-form-input__action {
      position: absolute;
      top: rem($input-padding-y + ($input-height - $input-action-size - (2 * $input-padding-y)) * 0.5);
      right: rem($input-padding-x);
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem($input-action-size);
      height: rem($input-action-size);
      padding: 0;
      font-size: rem($input-action-size);
      cursor: pointer;
      background: transparent;
      border: 0;
    }
}

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-input--error {
  --input-border-color: transparent;

  .c-form-input__control {
    &:not(:focus) {
      --input-background: var(--color-error-50);
      --input-outline-color: var(--color-error-500);
      @include input-outline;
    }
  }
}
