/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Focus
 * Type: Generic
 *
 * Description:
 *   JavaScript polyfill to :focus-visible
 *   https://github.com/WICG/focus-visible
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

:focus:not(:focus-visible) {
  outline: 0;
}

:focus-visible {
  outline: rem(2px) solid var(--color-outline);
  outline-offset: rem(2px);
}
