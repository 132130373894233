/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Link
 * Type: Component
**/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

$link-font-size: $font-size-small;
$link-weight: $font-bold !default;
$link-color-icon: var(--color-secondary-700) !default;
$link-has-primary: false !default;
$link-has-delete: false !default;
$link-has-toggler: false !default;

/*******************************************************************************
* Default styles
*******************************************************************************/

.c-link {
  --color: inherit;
  --color-icon: #{ $link-color-icon };
  --color-hover: var(--color-primary-700);

  @include appearance;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0;
  font-size: rem($link-font-size);
  font-weight: $link-weight;
  line-height: math.div(22px, $link-font-size);
  text-align: center;
  text-decoration: none;
  color: var(--color);
  background: none transparent;
  border: 0;
}

  .c-link__icon {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
    margin: em(7px 4px, 16px);
    font-size: em(16px, $link-font-size);
    color: var(--color-icon);
  }

  .c-link__label {
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    margin: em(4px, $link-font-size);
  }

/*******************************************************************************
 * States
*******************************************************************************/

/**
 * Interactive
*******************************************************************************/

a.c-link,
button.c-link {
  cursor: pointer;
  @include transition(color);

  .c-link__icon {
    @include transition(color);
  }

  &:hover {
    --color: var(--color-hover);

    text-decoration: none;

    .c-link__icon {
      color: var(--color-hover);
    }
  }
}

/*******************************************************************************
 * Variant
*******************************************************************************/

/**
 * Primary
*******************************************************************************/

@if ($link-has-primary) {
  .c-link--primary {
    --color-hover: var(--color-secondary-100);
  }
}

/**
 * Delete
*******************************************************************************/

@if ($link-has-delete) {
  .c-link--delete {
    --color: var(--color-neutral-500);
    --color-icon: inherit;
    --color-hover: var(--color-error-500);
  }
}

/**
 * Reverse
*******************************************************************************/

.c-link--reverse {
  flex-direction: row-reverse;

  :first-child {
    margin-right: 0;
  }

  :last-child {
    margin-left: 0;
  }
}

.c-link:not(.c-link--reverse) {
  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
}

/**
 * Ellipsis
*******************************************************************************/

.c-link--ellipsis {
  .c-link__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/**
 * Underlined
*******************************************************************************/

a.c-link--underlined,
button.c-link--underlined {
  .c-link__label {
    text-decoration: underline;
  }

  &:hover {
    .c-link__label {
      text-decoration: none;
    }
  }
}

/**
 * Toggler
*******************************************************************************/

@if ($link-has-toggler) {
  button.c-link--toggler {
    --color-icon: var(--color-primary-dark);
    --rotate-icon: 0turn;

    font-weight: $font-bold;

    .c-link__icon {
      position: relative;
      width: 1em;
      height: 1em;
      transform: rotate(var(--rotate-icon));
      @include transition(transform);

      &::before,
      &::after {
        --rotate-minus: 0turn;

        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: em(11px, 16px);
        height: em(2px, 16px);
        background-color: currentcolor;
        border-radius: em(2px, 16px);
        transform: translate(-50%, -50%) rotate(var(--rotate-minus));
        @include transition(transform);
      }

      &::before {}

      &::after {
        --rotate-minus: -0.25turn;
      }
    }

    &:hover {
      --rotate-icon: 0.5turn;
      --color: var(--color-primary-dark);
    }

    &[aria-expanded="true"] {
      .c-link__icon::after {
        --rotate-minus: 0.5turn;
      }
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-link {
    break-inside: avoid;

    * {
      color: inherit !important;
    }
  }
}
