/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Main
 * Type: Object
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$main-has-alternate: false !default;
$main-has-spaced: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-main {
  display: flex;
  flex-direction: column;
}

  .o-main__head {
    flex: 0 0 auto;
    @include fluid-size(24px, 40px, padding-top);
  }

  .o-main__body {
    flex: 1 0 auto;
    @include fluid-size(20px, 60px, padding-top);
    @include fluid-size(20px, 60px, padding-bottom);
  }

/*******************************************************************************
 * Modifiers
*******************************************************************************/

@if $main-has-alternate {
  .o-main--alternate {
    background-color: var(--color-background);

    .o-main__head {
      --gradient: var(--color-primary-100);

      @include fluid-size(20px, 120px, padding-bottom);
      background-image: linear-gradient(to bottom, var(--gradient), transparent);
    }

    .o-main__separator {
      --from: transparent;
      --to: var(--color-primary-800);
      --ratio: 50%;

      background-image:
        linear-gradient(
          to bottom,
          var(--from) 0%,
          var(--from) var(--ratio),
          var(--to) var(--ratio),
          var(--to) 100%
        );
    }

    .o-main__separator--to-dark {
      --ratio: 74%;

      + .o-main__body {
        color: var(--color-foreground-dark);
        background-color: var(--color-primary-800);
      }
    }

    .o-main__separator--from-dark {
      --ratio: 87%;
      --from: var(--color-primary-800);
      --to: transparent;
    }
  }
}

@if $main-has-spaced {
  .o-main--spaced {
    --space-top: #{fluid-size-value(40px, 80px)};
    --space-bottom: #{fluid-size-value(40px, 80px)};

    .o-main__head {
      padding: var(--space-top) 0 var(--space-bottom) 0;
    }
  }
}
