/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Icon
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$icon-has-medium: false !default;
$icon-has-large: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-icon {
  --size: 1em;

  display: inline-block;
  width: var(--size);
  height: var(--size);
  vertical-align: -0.2em; // https://fvsch.com/code/svg-icons/how-to/#section-styling
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

@if $icon-has-medium {
  .c-icon--medium {
    --size: 1.25em;

    vertical-align: -0.3em;
  }
}

@if $icon-has-large {
  .c-icon--large {
    --size: 1.5em;

    vertical-align: -0.4em;
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

 @media print {
  .c-icon {
    break-inside: avoid;
  }
}
