/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: A11Y skip links
 * Type: Component
**/

@use "sass-rem/rem";

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-a11y-skip {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $global-header-zindex + 1;
  padding-top: rem(20px);
  padding-bottom: rem(20px);
  color: var(--color-white);
  background: var(--color-black);
  transform: translateY(-200%);

  &:focus-within {
    transform: translateY(0);
  }

  @include mq($until: large) {
    display: none;
  }
}

  .c-a11y-skip__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

    .c-a11y-skip__items {
      display: flex;
      flex: 1 0 auto;
      flex-wrap: wrap;
      align-items: center;
      margin: rem(-20px);
    }

      .c-a11y-skip__item {
        padding: rem(20px);
      }

        .c-a11y-skip__link {
          color: inherit;

          &:visited {
            color: inherit;
          }
        }

    .c-a11y-skip__actions {
      display: flex;
      flex: 0 0 auto;
      flex-wrap: wrap;
      align-items: flex-end;
      padding: rem(20px 0 20px 20px);
    }

/**
 * Print
 **********************************************************************************/

 @media print {
  .c-a11y-skip {
    display: none !important;
  }
}
