/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Tag
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-tag {
  --icon: inherit;

  display: inline-flex;
}

  .c-tag__icon {
    margin-top: 0.0625em;
    margin-right: 0.5em;
    font-size: 1.2857em;
    color: var(--icon);
  }

  .c-tag__label {}

a.c-tag,
button.c-tag {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

a.c-tag--underlined,
button.c-tag--underlined {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
