/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Headband
 * Type: Component
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-headband {
  position: relative;
  padding: rem(12px) 0;
  overflow: hidden;
}

  .c-headband__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .c-headband__aside {
      flex: 0 0 auto;
      padding-right: rem(16px);
      line-height: 0;
    }

    .c-headband__main {
      flex: 1 1 auto;
    }

      .c-headband__title {
        @include h6;
        font-family: var(--font-brand);
        font-weight: $font-bold;
      }

      .c-headband__link {
        @if $namespace == "lme" {
          --color: var(--color-shop-dark);
          --color-hover: var(--color-shop-dark);
        } @else if $namespace == "kaizen" {
          --color: inherit;
          --color-hover: inherit;
        }
      }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($from: regular) {
  .c-headband__aside {
    position: relative;
    align-self: normal;
    width: rem(57px);
    height: 100%;
  }

    .c-headband__figure {
      position: absolute;
      top: 0;
      left: 0;
    }

  .c-headband__main {
    display: flex;
    flex-grow: 0;
    align-items: center;
  }

    .c-headband__title {
      padding-right: rem(16px);
    }
}
