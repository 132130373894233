/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Field
 * Type: Component
 * Description: A group of elements which purpose is to get user's input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * [1] Reset fieldset styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
 */
.c-form-field {
  display: inline-block;
  width: 100%;
  min-width: 0; /* [1] */
  padding: 0.01em 0 0; /* [1] */
  margin: 0; /* [1] */
  border: 0; /* [1] */

  body:not(:-moz-handler-blocked) & {
    display: table-cell; /* [1] */
  }

  &[aria-hidden] {
    animation: field-open $animations-transition-duration $animations-timing-function;
    @include transition(opacity transform);

    &.is-hiding {
      opacity: 0;
      transform: translateY(#{ rem(-1 * $spacing-regular) });
    }
  }

  &[aria-hidden="true"] {
    display: none;
  }
}

  /**
   * [1] Reset legend styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
   */
  .c-form-field__label {
    display: table; /* [1] */
    padding: 0; /* [1] */
    margin: 0;
    color: var(--color-black);
    @include transition(color);

    &--legend {
      @include h5;
      margin-top: rem(10px);
      margin-bottom: rem(10px);
      font-family: var(--font-brand);
      font-weight: $font-bold;
      color: var(--color-foreground);

      small {
        @include text-small;
        font-family: $font-default;
        font-weight: $font-regular;
      }
    }

    &--spaced {
      margin-bottom: rem(20px);
    }
  }

  .c-form-field__hint {
    margin-bottom: rem(6px);

    a {
      font-weight: $font-bold;
      color: var(--color-primary);
    }
  }

  .c-form-field__feedback {
    margin-bottom: rem(6px);
  }

  .c-form-field__control {
    display: flex;

    &:not(:first-child) {
      margin-top: rem(4px);
    }

    > * {
      flex: var(--flex, 1 1 auto);

      &:not(:first-child) {
        margin-left: rem(8px);
      }
    }
  }

  .c-form-field__controls {
    display: flex;
    gap: rem(8px);

    > * {
      flex: 1 1 auto;
    }

    > .c-form-field__control {
      margin: 0;
    }

    &:not(:first-child) {
      margin-top: rem(4px);
    }
  }

  .c-form-field__control--wrap {
    @include mq($until: tiny) {
      flex-direction: column;

      > * {
        &:not(:first-child) {
          margin-top: rem(16px);
          margin-left: 0;
        }
      }
    }
  }

  .c-form-field__control--prefix {
    flex: 0 0 auto;
    width: rem(130px);
  }

  .c-form-field--toggler,
  .c-form-field__toggler {
    @include mq($from: large) {
      display: none;
    }
  }

/**
 * Focus styles
 ******************************************************************************/

.c-form-field:focus-within {
  .c-form-field__label {
    color: var(--color-primary-800);
  }
}

/**
 * Option list
*******************************************************************************/

.c-form-field--optionset {
  .c-form-field__control {
    flex-direction: column;

    &:not(:first-child) {
      margin-top: rem(8px);
    }

    > *:not(:first-child) {
      margin-top: rem(8px);
      margin-left: 0;
    }
  }
}

.c-form-field--action {
  align-self: flex-end;
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes field-open {
  from {
    opacity: 0;
    transform: translate3d(0, #{ rem(-1 * $spacing-regular) }, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
