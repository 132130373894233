/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Animations
 * Type: Tool
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:string";

/*******************************************************************************
 * Default styles
*******************************************************************************/

/*
  Declare transitions more easily.
  Usage :
    @include transition(transform);
    @include transition(background color transform, 0.5s, ease-out, 0.25s);
*/
@mixin
  transition(
    $properties,
    $duration: $animations-transition-duration,
    $easing: $animations-timing-function,
    $delay: 0s
) {

  $r: "";
  $s: "";

  @each $property in $properties {
    $r: $r + "#{$property} #{$duration} #{$easing} #{$delay}, ";
    $s: $s + "#{$property}, ";
  }

  transition: string.slice(#{$r}, 0, -3);
  will-change: string.slice(#{$s}, 0, -3);
}
