/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$footer-foreground: var(--color-foreground-dark) !default;
$footer-background: var(--color-primary-800) !default;
$footer-emphasis: var(--color-border) !default;
$footer-has-separator: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer {
  --foreground: #{ $footer-foreground };
  --background: #{ $footer-background };

  padding: rem(15px 0);
  color: var(--foreground);
  background-color: var(--background);

  @if $footer-has-separator {
    border-top: var(--separator);
  }
}

  .c-footer__wrapper {}

    .c-footer__head,
    .c-footer__body {
      padding-top: rem(15px);
      padding-bottom: rem(15px);
    }

    .c-footer__head {
      position: relative;
      text-align: center;
    }

      .c-footer__heading {
        > * {
          margin-top: 0;
        }

        > * + * {
          margin-top: rem($spacing-regular);
        }
      }

        .c-footer__logo {
          @include fluid-size(28px, 36px);
        }

        .c-footer__socials {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          font-size: rem(14px);
          line-height: 1;
        }

          .c-footer__social-label {
            padding: em(9px, 14px);
          }

          .c-footer__social {
            display: inline-block;
            padding: em(9px, 24px);
            font-size: em(24px, 14px);
            text-decoration: none;
            opacity: 0.4;
            @include transition(opacity);

            &:hover {
              opacity: 1;
            }
          }

      .c-footer__button {}

    .c-footer__body {}

      .c-footer__nav {}

        .c-footer__items {
          --min: #{rem(280px)};
          --gap: #{rem($spacing-large)};

          display: grid;
          padding: 0;
          list-style: none;
          grid-gap: var(--gap);

          @supports (width: min(var(--min), 100%)) {
            grid-template-columns: repeat(auto-fit, minmax(min(var(--min), 100%), 1fr));
          }

          .c-footer__foot & {
            display: flex;
            gap: 0;
            margin: rem(-8px);
          }
        }

          .c-footer__item {
            > * {
              margin: 0;
            }

            > * + * {
              margin-top: rem(25px);
            }

            .c-footer__foot & {
              padding: rem(8px);
            }
          }

            .c-footer__title {
              @include h4;

              font-family: var(--font-brand);
              font-weight: $font-bold;

              &[aria-expanded] {
                cursor: pointer;
              }
            }

              .c-footer__icon {
                display: none;
                margin-left: rem($spacing-regular);

                [aria-expanded] > & {
                  display: inline-block;
                  transform: rotate(0turn);
                  @include transition(transform);
                }

                [aria-expanded="true"] > & {
                  transform: rotate(-0.5turn);
                }
              }

            .c-footer__link {
              display: block;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }

            .c-footer__subtitle {
              position: relative;
              font-weight: $font-bold;
              color: $footer-emphasis;

              &::after {
                content: "";
                display: block;
                width: 100%;
                margin-top: rem(10px);
                border-bottom: var(--separator);
              }

              .c-footer__subitem:not(:first-child) > & {
                margin-top: rem(25px);
              }
            }

            .c-footer__subitems {
              padding: 0;
              list-style: none;

              > * {
                margin: 0;
              }

              > * + * {
                margin-top: rem(10px);
              }

              &[aria-hidden] {
                @include transition(opacity transform);
                animation: footer-target-show $animations-transition-duration $animations-timing-function;
              }

              &.is-hiding {
                opacity: 0;
                transform: translate3d(0, #{ rem(-$spacing-regular) }, 0);
              }

              &[aria-hidden="true"] {
                display: none;
              }
            }

              .c-footer__subitem {
                > * {
                  margin: 0;
                }

                > * + * {
                  margin-top: rem(10px);
                }
              }

    .c-footer__foot {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: rem(16px);
      margin-top: rem($spacing-large);
      font-size: rem($font-size-small);
      border-top: var(--separator);
    }

      .c-footer__note {
        margin: 0;
      }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: regular) {
  .c-footer {
    text-align: center;
  }

      .c-footer__items {
        --min: #{rem(400px)};

        .c-footer__foot & {
          flex-direction: column;
          justify-content: center;
        }
      }

        .c-footer__title[data-footer-toggler] {
          position: relative;
        }

        .c-footer__images {
          > * {
            justify-content: center;
          }
        }

    .c-footer__foot {
      display: block;
    }

      .c-footer__subtitle {
        &::after {
          width: rem(16px);
          margin-right: auto;
          margin-left: auto;
        }
      }

    .c-footer__note {
      margin-top: rem(16px);
    }
}

@include mq($until: large) {
  .c-footer__action {
    margin-top: rem(24px);
  }
}

@include mq($from: large) {
  .c-footer {
    padding-top: rem($spacing-large);
    padding-bottom: rem($spacing-large);
  }

    .c-footer__head,
    .c-footer__body {
      padding-top: rem($spacing-large);
      padding-bottom: rem($spacing-large);
    }

      .c-footer__items {
        .c-footer__body & {
          --gap: #{rem($spacing-large * 1.5)};
        }
      }

      .c-footer__action {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      .c-footer__action + .c-footer__action {
        right: auto;
        left: 0;
        transform: translateY(-50%);
      }
}

@include mq($from: huge) {
  .c-footer__items {
    .c-footer__body & {
      --gap: #{rem($spacing-huge)};
    }
  }
}

/*******************************************************************************
 * Animations
*******************************************************************************/

 @keyframes footer-target-show {
  from {
    opacity: 0;
    transform: translate3d(0, #{ rem(-$spacing-small) }, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-footer {
    break-inside: avoid;
    border-top: rem(1px) solid color("black");
  }
}
