/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Title
 * Type: Component
**/

/*******************************************************************************
 * Mixins
*******************************************************************************/

// Generate overflowing link with pseudo-element
@mixin title($root) {
  .c-#{$root}__mainlink {
    font-weight: inherit;
    text-decoration: none;
    color: inherit;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &:hover {
      text-decoration: underline;
      color: inherit;
    }
  }
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-title {
  @include title("title");

  position: relative;
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-title {
    break-inside: avoid;
  }
}
