/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Social
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$social-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-social {
  --theme: var(--color-primary);
  --theme-dark: var(--color-primary-dark);

  position: relative;
  padding: rem(24px);
  font-size: rem($social-font-size);
  color: var(--color-foreground);
  background-color: var(--color-background);
  border: var(--separator);
  border-radius: rem(8px);

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: rem(24px);
  }
}

  .c-social__body,
  .c-social__foot {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem(16px);
    }
  }

  .c-social__head {
    display: flex;
  }

    .c-social__heading {
      flex: 1 1 auto;

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

      .c-social__title {
        @include fluid-size($font-size-small, $font-size-regular);

        font-weight: $font-bold;
      }

      .c-social__subtitle {
        color: var(--color-neutral-600);
      }

    .c-social__icon {
      @include fluid-size(32px, 40px);
      flex: 0 0 auto;
      margin-left: rem(16px);
      color: var(--theme);
    }

  .c-social__body {}

    .c-social__richtext {
      --color: var(--theme-dark);
    }

  .c-social__foot {}

    .c-social__link {
      --color: var(--theme-dark);
      --color-hover: var(--theme-dark);
    }
