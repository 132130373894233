/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Prefooter
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$prefooter-font-size: $font-size-small;
$prefooter-wrapper-size: $wrapper-size !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-prefooter {
  --foreground: var(--color-black);
  --background: var(--color-secondary-50);
  --gap: #{ rem(14px) };

  padding: rem(30px 0);
  font-size: rem($prefooter-font-size);
  color: var(--foreground);
  background-color: var(--background);
}

  .c-prefooter__wrapper {
    --size: #{ rem($prefooter-wrapper-size) };
  }

    .c-prefooter__main {}

      .c-prefooter__image {
        width: rem(60px);
        height: auto;
        margin: 0 auto;
      }

      .c-prefooter__richtext {
        > * + * {
          margin-top: rem(5px);
        }
      }

        .c-prefooter__title {
          @include h5;
        }

    .c-prefooter__aside {}

      .c-prefooter__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

        .c-prefooter__item {
          position: relative;
          display: flex;
          align-items: center;

          &:not(:first-child) {
            &::before {
              content: "";
              position: absolute;
              border: rem(1px) solid var(--color-primary-600);
            }
          }
        }

          .c-prefooter__marker {
            flex: 0 0 auto;
            margin-right: rem($spacing-small);
            font-size: rem(28px);
            color: var(--color-primary-600);
          }

          .c-prefooter__content {
            flex: 0 0 auto;

            > * {
              margin: 0;
            }
          }

            .c-prefooter__label {
              font-family: var(--font-brand);
            }

            .c-prefooter__sublabel {
              font-weight: $font-bold;
              color: var(--color-primary-600);
            }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@include mq($until: small) {
  .c-prefooter__item {
    &:not(:first-child) {
      padding-top: calc(var(--gap) + #{ rem(1px) });
      margin-top: var(--gap);

      &::before {
        top: 0;
        left: 50%;
        width: rem(26px);
        transform: translateX(-50%);
      }
    }
  }
}

@include mq($from: small) {
  .c-prefooter__items {
    flex-direction: row;
  }

    .c-prefooter__item {
      &:not(:first-child) {
        padding-left: calc(var(--gap) + #{ rem(1px) });
        margin-left: var(--gap);

        &::before {
          top: 50%;
          left: 0;
          height: rem(26px);
          transform: translateY(-50%);
        }
      }
    }
}

@include mq($until: regular) {
  .c-prefooter__wrapper {
    > * {
      margin: 0;
    }

    > * + * {
      margin-top: var(--gap);
    }
  }

    .c-prefooter__main {
      text-align: center;
    }

      .c-prefooter__image {
        margin-top: var(--gap);
      }
}

@include mq($from: regular) {
  .c-prefooter__wrapper {
    display: flex;
    flex: 1 0 auto;
    align-items: center;

    > * + * {
      margin-left: var(--gap);
    }
  }

    .c-prefooter__main {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }

      .c-prefooter__image {
        flex: 0 0 auto;
        order: -1;
        margin-right: var(--gap);
      }

      .c-prefooter__richtext {
        flex: 1 1 auto;
      }

  .c-prefooter__aside {
    flex: 0 0 auto;
  }
}

@include mq($from: large) {
  .c-prefooter {
    --gap: #{ rem(40px) };
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-prefooter {
    break-inside: avoid;
    border-top: rem(1px) solid color("black");
  }
}
