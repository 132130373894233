/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Blocks
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

$blocks-gutter-start: $spacing-large !default;
$blocks-gutter-end: $spacing-huge !default;
$blocks-has-padded: false !default;
$blocks-has-small: false !default;
$blocks-has-large: false !default;
$blocks-has-wall: false !default;
$blocks-gutter-start-small: $spacing-regular !default;
$blocks-gutter-end-small: $spacing-large !default;
$blocks-gutter-start-large: $spacing-huge !default;
$blocks-gutter-end-large: $spacing-huge * 2 !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-blocks {
  position: relative;
  width: 100%;

  > .o-blocks__item {
    position: relative;
    @include fluid-size($blocks-gutter-start, $blocks-gutter-end, margin);
    margin-right: 0;
    margin-left: 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/**
 * Variants
*******************************************************************************/

@if ($blocks-has-padded) {
  .o-blocks--padded {
    > .o-blocks__item {
      @include fluid-size(math.div($blocks-gutter-start, 2), math.div($blocks-gutter-end, 2), padding);
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@if ($blocks-has-small) {
  .o-blocks--small {
    > .o-blocks__item {
      @include fluid-size($blocks-gutter-start-small, $blocks-gutter-end-small, margin);
      margin-right: 0;
      margin-left: 0;
    }

  @if ($blocks-has-padded) {
      &.o-blocks--padded {
        > .o-blocks__item {
          @include fluid-size(math.div($blocks-gutter-start-small, 2), math.div($blocks-gutter-end-small, 2), padding);
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

@if ($blocks-has-large) {
  .o-blocks--large {
    > .o-blocks__item {
      @include fluid-size($blocks-gutter-start-large, $blocks-gutter-end-large, margin);
      margin-right: 0;
      margin-left: 0;
    }

  @if ($blocks-has-padded) {
      &.o-blocks--padded {
        > .o-blocks__item {
          @include fluid-size(math.div($blocks-gutter-start-large, 2), math.div($blocks-gutter-end-large, 2), padding);
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
}

@if ($blocks-has-wall) {
  .o-blocks {
    > .o-blocks__item--wall {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: rem(-400px);
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        background-image: linear-gradient(to bottom, transparent 0%, var(--color-background) #{ rem(380px) });
        pointer-events: none;
      }
    }
  }
}
