/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Text
 * Type: Utilities
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$text-has-left: false !default;
$text-has-center: false !default;
$text-has-right: false !default;
$text-has-inherit: false !default;
$text-has-bold: false !default;
$text-has-small: false !default;
$text-has-tag: false !default;
$text-has-emphasis: false !default;
$text-has-introduction: false !default;
$text-has-chapo: false !default;
$text-has-link: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

@if ($text-has-left) {
  .u-text-left {
    text-align: left;
  }
}

@if ($text-has-center) {
  .u-text-center {
    text-align: center;
  }
}

@if ($text-has-right) {
  .u-text-right {
    text-align: right;
  }
}

@if ($text-has-inherit) {
  .u-text-inherit {
    text-align: inherit;
  }
}

@if ($text-has-bold) {
  .u-text-bold {
    font-weight: $font-bold;
  }
}

@if ($text-has-small) {
  .u-text-small {
    @include text-small;
  }
}

@if ($text-has-tag) {
  .u-text-tag {
    @include tag;
  }
}

@if ($text-has-emphasis) {
  .u-text-emphasis {
    @include text-emphasis;
  }
}

@if ($text-has-introduction) {
  .u-text-introduction {
    @include fluid-size(16px, 20px);
  }
}

@if ($text-has-chapo) {
  .u-text-chapo {
    @include fluid-size(18px, 20px);
    font-weight: $font-bold;
  }
}

@if ($text-has-link) {
  .u-text-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
