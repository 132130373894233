/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Global
 * Type: Tool
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

/*
  Customize the scrollbar for webkit browsers
*/
@mixin global-custom-scrollbar() {
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    position: absolute;
    top: 0;
    left: 0;
    width: rem(5px);
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: color("black");
    border-radius: rem(3px);
  }
}

@mixin shadow($offset-x: 0, $offset-y: 4px, $blur: 20px) {
  box-shadow: rem($offset-x $offset-y $blur) var(--shadow);
}

@mixin shadow-on($offset-x: 0, $offset-y: 4px, $blur: 20px) {
  @include shadow($offset-x, $offset-y, $blur);
}

@mixin shadow-off($offset-x: 0, $offset-y: 0, $blur: 0) {
  @include shadow($offset-x, $offset-y, $blur);
}

@mixin drop-shadow($offset-x: 0, $offset-y: 4px, $blur: 12px) {
  filter: drop-shadow(rem($offset-x $offset-y $blur) var(--shadow));
}

@mixin drop-shadow-on($offset-x: 0, $offset-y: 4px, $blur: 12px) {
  @include drop-shadow($offset-x, $offset-y, $blur);
}

@mixin drop-shadow-off($offset-x: 0, $offset-y: 0, $blur: 0) {
  @include drop-shadow($offset-x, $offset-y, $blur);
}
