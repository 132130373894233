/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Tool
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
* Default styles
*******************************************************************************/

// Headings

@mixin h-xxl {
  @include fluid-size(60px, 164px);
  line-height: 1.2;
}

@mixin h-xl {
  @include fluid-size(50px, 112px);
  line-height: 1.2;
}

@mixin h-l {
  @include fluid-size(40px, 88px);
  line-height: 1.2;
}

@mixin h-m {
  @include fluid-size(35px, 72px);
  line-height: 1.2;
}

@mixin h1 {
  @include fluid-size($font-h1-min, $font-h1-max);
  line-height: 1.2;
}

@mixin h2 {
  @include fluid-size($font-h2-min, $font-h2-max);
  line-height: 1.2;
}

@mixin h3 {
  @include fluid-size($font-h3-min, $font-h3-max);
  line-height: 1.2;
}

@mixin h4 {
  @include fluid-size($font-h4-min, $font-h4-max);
  line-height: 1.2;
}

@mixin h5 {
  @include fluid-size($font-h5-min, $font-h5-max);
  line-height: 1.2;
}

@mixin h6 {
  @include fluid-size($font-h6-min, $font-h6-max);
  line-height: 1.2;
}

@mixin content {
  @if ($font-richtext-min < $font-richtext-max) {
    @include fluid-size($font-richtext-min, $font-richtext-max);
  }
}

@mixin tag {
  font-size: rem($font-size-small);
  font-weight: $font-bold;
  text-transform: uppercase;
}

@mixin text-small {
  font-size: rem($font-size-small);
}

@mixin text-regular {
  font-size: rem($font-size-regular);
}

@mixin text-medium {
  font-size: rem($font-size-medium);
}

@mixin text-large {
  font-size: rem($font-size-large);
}

@mixin text-emphasis {
  @include fluid-size(16px, 20px);
  font-family: var(--font-brand);
  font-weight: $font-bold;
}

// Generate font-face
@mixin
  font-face(
    $global-font-family,
    $file-name,
    $weight: normal,
    $style: normal,
    $id: $global-font-family,
    $suffix: ""
) {
  @font-face {
    font-display: swap;
    font-family: $global-font-family;
    font-style: $style;
    font-weight: $weight;
    src: url("#{$font-folder}#{$file-name}#{$suffix}.woff");
    src:
      url("#{$font-folder}#{$file-name}#{$suffix}.woff2")  format("woff2"),
      url("#{$font-folder}#{$file-name}#{$suffix}.woff")   format("woff");
  }
}

// Create rem base font-size with line-height
@mixin font-size($global-font-size: $global-font-size, $line-height: auto) {
  @include rem(font-size, $global-font-size);

  @if $line-height == auto {
    line-height: math.ceil($global-font-size / $global-line-height-px) * ($global-line-height-px / $global-font-size);
  } @else if type-of($line-height) == number or $line-height == inherit or $line-height == normal {
    @if type-of($line-height) == number and unit($line-height) == px {
      line-height: $line-height / $global-font-size;
    } @else {
      line-height: $line-height;
    }
  }
}

$fluid-size-fallback: false !default;

/* stylelint-disable max-line-length */
@function fluid-size-value($size-min, $size-max, $vw-min: 400px, $vw-max: 960px) {
  $slope: math.div($size-max - $size-min, $vw-max - $vw-min);
  $slope-to-unit: $slope * 100 * 1vw;
  $intercept-rem: rem($size-min - $slope * $vw-min);
  $size-min-rem: rem($size-min);
  $size-max-rem: rem($size-max);

  @return clamp(#{$size-min-rem}, #{$slope-to-unit} + #{$intercept-rem}, #{$size-max-rem});
}

@mixin fluid-size($size-min: 16px, $size-max: 18px, $property: font-size, $vw-min: 400px, $vw-max: 960px) {
  $u1: math.unit($size-min);
  $u2: math.unit($size-max);
  $u3: math.unit($vw-min);
  $u4: math.unit($vw-max);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    $value: fluid-size-value($size-min, $size-max, $vw-min, $vw-max);

    @if $fluid-size-fallback {
      #{$property}: rem($size-min);

      @supports (#{$property}: clamp(#{ rem($size-min) }, #{ rem($size-limit) } + #{ $vw-const }, #{ rem($size-max) })) {
        #{$property}: #{ $value };
      }
    } @else {
      #{$property}: #{ $value };
    }
  } @else {
    @error "All units must be the same.";
  }
}
/* stylelint-enable max-line-length */
