/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Generic
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

@if $namespace == "lme" or $namespace == "both" {
  // @include
  //   font-face(
  //     "Hind",
  //     "hind-light",
  //     $font-light,
  //     normal,
  //   );

  @include
    font-face(
      "Hind",
      "hind-regular",
      $font-regular,
      normal,
    );

  // @include
  //   font-face(
  //     "Hind",
  //     "hind-medium",
  //     $font-medium,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Hind",
  //     "hind-semibold",
  //     $font-semibold,
  //     normal,
  //   );

  @include
    font-face(
      "Hind",
      "hind-bold",
      $font-bold,
      normal,
    );
}

@if $namespace == "kaizen" or $namespace == "both" {
  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-thin",
  //     $font-thin,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-thinitalic",
  //     $font-thin,
  //     italic,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-extralight",
  //     $font-extralight,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-extralightitalic",
  //     $font-extralight,
  //     italic,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-light",
  //     $font-light,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-lightitalic",
  //     $font-light,
  //     italic,
  //   );

  @include
    font-face(
      "Barlow",
      "barlow-regular",
      $font-regular,
      normal,
    );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-regularitalic",
  //     $font-regular,
  //     italic,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-medium",
  //     $font-medium,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-mediumitalic",
  //     $font-medium,
  //     italic,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-semibold",
  //     $font-semibold,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-semibolditalic",
  //     $font-semibold,
  //     italic,
  //   );

  @include
    font-face(
      "Barlow",
      "barlow-bold",
      $font-bold,
      normal,
    );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-bolditalic",
  //     $font-bold,
  //     italic,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-extrabold",
  //     $font-extrabold,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-extrabolditalic",
  //     $font-extrabold,
  //     italic,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-black",
  //     $font-black,
  //     normal,
  //   );

  // @include
  //   font-face(
  //     "Barlow",
  //     "barlow-blackitalic",
  //     $font-black,
  //     italic,
  //   );
}
