/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Key
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$key-font-size: $font-size-regular;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-key {
  --icon-color: inherit;

  font-size: rem($key-font-size);
  text-align: center;

  > * + * {
    margin-top: rem($spacing-regular);
  }
}

  .c-key__icon {
    max-width: 100%;
    height: auto;
    font-size: em(130px, $key-font-size);
    color: var(--icon-color);
  }

  .c-key__label {
    @include h5;

    display: block;
    font-family: var(--font-brand);
    font-weight: $font-bold;
  }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-key {
    break-inside: avoid;
  }
}
