/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Wrapper
 * Type: Object
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$wrapper-gutter: 24px;
$wrapper-size: 1200px;

$wrappers-sizes: (
  "nano": 180px,
  "micro": 346px,
  "mini": 486px,
  "tiny": 550px,
  "little": 580px,
  "small": 690px,
  "regular": 792px,
  "medium": 846px,
  "large": 1000px,
) !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-wrapper {
  --size: #{ rem($wrapper-size) };
  --gutter: #{ rem($wrapper-gutter) };

  box-sizing: content-box;
  width: calc(100% - ( var(--gutter) * 2 ));
  max-width: var(--size);
  padding-right: var(--gutter);
  padding-left: var(--gutter);
  margin-right: auto;
  margin-left: auto;
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

// Sizes
@each $namespace, $size in $wrappers-sizes {
  .o-wrapper--#{ $namespace } {
    --size: #{ rem($size) };
  }
}

// No horizontal paddings
.o-wrapper--wide {
  --gutter: 0;
}

.o-wrapper--stretch {
  > * {
    height: 100%;
  }
}

.o-wrapper--left {
  margin-left: 0;
}
