/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Page
 * Type: Object
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-page {
  --background: transparent;
  --color: inherit;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: var(--color);
  background-color: var(--background);

  &--gradient {
    --gradient: var(--color-primary-100);
    background-image: linear-gradient(to bottom, var(--gradient), transparent);
  }

  &--dark {
    --background: var(--color-primary-800);
    --color: var(--color-white);
  }

  &--light {
    --background: var(--color-primary-50);
  }
}

  .o-page__header,
  .o-page__footer {
    flex: 0 0 auto;
  }

  .o-page__header {
    .o-page--alternate & {
      background-color: var(--color-primary-100);
    }

    .o-page--sticky & {
      padding-top: var(--header-height, #{ rem(150px) });
    }
  }

  .o-page__main {
    flex: 1 0 auto;

    .o-page--spaced & {
      @include fluid-size($spacing-large, $spacing-huge, padding-top);
      @include fluid-size($spacing-large, $spacing-huge, padding-bottom);
    }
  }

  .o-page__footer {}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .o-page__header,
  .o-page__footer {
    display: none !important;
  }
}
