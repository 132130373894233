/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Fonts
 * Type: Setting
**/

/*******************************************************************************
 * @font-face helpers
*******************************************************************************/

$font-folder: "../fonts/common/" !default;

/*******************************************************************************
 * Families
*******************************************************************************/

$font-serif: "Rufina", serif !default;
$font-sans-serif: "Verdana", sans-serif;
$font-default: $font-sans-serif;
$font-brand: $font-serif !default;

/*******************************************************************************
 * Types
*******************************************************************************/

$font-size-tiny: 12px;
$font-size-small: 14px;
$font-size-regular: 16px;
$font-size-medium: 20px;
$font-size-large: 24px;

/*******************************************************************************
 * Weights
*******************************************************************************/

$font-thin: 100;
$font-extralight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

/*******************************************************************************
 * Headings
*******************************************************************************/

/**
 * Min
*******************************************************************************/

$font-h1-min: 30px !default;
$font-h2-min: 26px !default;
$font-h3-min: 24px !default;
$font-h4-min: 22px !default;
$font-h5-min: 18px !default;
$font-h6-min: 16px !default;
$font-richtext-min: 16px !default;

/**
 * Max
*******************************************************************************/

$font-h1-max: 56px !default;
$font-h2-max: 46px !default;
$font-h3-max: 36px !default;
$font-h4-max: 26px !default;
$font-h5-max: 20px !default;
$font-h6-max: 18px !default;
$font-richtext-max: 16px !default;
