/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Highlight
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

$highlight-font-size: $font-size-regular;
$highlight-foreground: var(--color-foreground-dark) !default;
$highlight-background: var(--color-primary-700) !default;
$highlight-center: false !default;
$highlight-has-faded: false !default;
$highlight-has-big: false !default;
$highlight-has-spaced: false !default;
$highlight-variants: () !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-highlight {
  --foreground: #{ $highlight-foreground };
  --background: #{ $highlight-background };
  --aside: #{ rem(140px) };

  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: rem(math.div($spacing-regular, 2));
  font-size: rem($highlight-font-size);
  color: var(--foreground);
  background-color: var(--background);
  border-radius: rem(10px);
}

  .c-highlight__head,
  .c-highlight__body,
  .c-highlight__foot {
    padding: rem(math.div($spacing-regular, 2));
  }

  .c-highlight__head,
  .c-highlight__foot {
    flex: 0 0 auto;
  }

  .c-highlight__head {
    flex-basis: var(--aside);
  }

  .c-highlight__body {
    flex: 1 1 100%;
  }

  .c-highlight__foot {}

  .c-highlight__close {
    @include appearance;

    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
  }

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@if $highlight-center {
  @include mq($until: regular) {
    .c-highlight {
      justify-content: center;
      text-align: center;
    }
  }
}

@include mq($from: regular) {
  .c-highlight {
    flex-wrap: nowrap;
  }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

@each $variant, $values in $highlight-variants {
  .c-highlight--#{ $variant } {
    @each $name, $value in $values {
      --#{$name}: var(--color-#{$value});
    }
  }
}

// @if $highlight-has-light {
//   .c-highlight--dark {
//     --foreground: var(--color-white);
//     --background: var(--color-primary-800);
//   }
// }

// @if $highlight-has-light {
//   .c-highlight--light {
//     --foreground: var(--color-foreground);
//     --background: var(--color-primary-100);
//   }
// }

// @if $highlight-has-shop {
//   .c-highlight--shop {
//     --foreground: var(--color-foreground);
//     --background: var(--color-shop-light);
//   }
// }

@if $highlight-has-faded {
  .c-highlight--faded {
    .c-highlight__head {
      mix-blend-mode: luminosity;
    }
  }
}

@if $highlight-has-big {
  .c-highlight--big {
    --aside: #{ rem(240px) };
  }
}

@if $highlight-has-spaced {
  .c-highlight--spaced {
    @include mq($from: large) {
      padding: rem($spacing-regular);

      .c-highlight__head,
      .c-highlight__body,
      .c-highlight__foot {
        padding: rem($spacing-regular);
      }
    }
  }
}

/*******************************************************************************
 * Print
*******************************************************************************/

@media print {
  .c-highlight {
    break-inside: avoid;
    border: rem(1px) solid color("black") !important;
  }
}
