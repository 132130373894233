/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Push
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$push-font-size: $font-size-small;
$push-min-ratio: 80% !default;
$push-min-width: auto !default;
$push-min-main-ratio: 50% !default;
$push-min-aside-width: auto !default;
$push-title-font: inherit !default;
$push-gap: 16px;

$push-has-action: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-push {
  --min-ratio: #{ rem($push-min-ratio) };
  --min-width: #{ rem($push-min-width) };
  --min-main-ratio: #{ rem($push-min-main-ratio) };
  --min-aside-width: #{ rem($push-min-aside-width) };
  --gap: #{$push-gap};

  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--gap);
  @include fluid-size(16px, 24px, padding);
  padding-right: 0;
  padding-left: 0;
  font-size: $push-font-size;
  border: var(--separator);
  border-right: 0;
  border-left: 0;
}

  .c-push__head {
    flex-basis: 100%;
  }

    .c-push__headline {
      margin: 0;
      font-size: 1em;
      font-weight: $font-bold;
      text-transform: uppercase;
    }

  .c-push__body {
    display: flex;
    flex-basis: 0;
    flex-wrap: wrap;
    flex-grow: 999;
    gap: var(--gap);
    min-width: var(--min-ratio);
  }

    .c-push__aside {
      flex-basis: var(--min-aside-width);
      flex-grow: 1;
      width: var(--min-aside-width);
      max-width: 100%;
      line-height: 0;
    }

      .c-push__figure {}

    .c-push__main {
      flex-basis: 0;
      flex-wrap: wrap;
      flex-grow: 999;
      min-width: var(--min-main-ratio);

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > * + * {
        margin-top: rem(4px);
      }
    }

      .c-push__tags {}

        a.c-push__tag {
          position: relative;
        }

      .c-push__title {
        @include h6;
        @include title($root: "push");

        font-family: $push-title-font;
        font-weight: $font-bold;
      }

      .c-push__subtitle {
        margin-top: rem(8px);
      }

      .c-push__richtext {
        display: block;
        display: -webkit-box;
        max-height: 3em;
        margin-top: rem(6px);
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--color-neutral-600);
      }

  .c-push__foot {
    flex-basis: var(--min-width);
    flex-grow: 1;
    align-self: center;
    width: var(--min-width);
    max-width: 100%;
  }

    .c-push__button {}

/*******************************************************************************
 * States
*******************************************************************************/

.c-push--interactive {
  cursor: pointer;

  .c-push__mainlink {
    @include transition(color text-decoration);
    text-decoration-color: transparent;
  }

  &:hover {
    .c-push__mainlink {
      text-decoration: underline;
      text-decoration-color: currentcolor;
      color: var(--color-shop-dark);
    }
  }
}

/*******************************************************************************
 * Modifiers
*******************************************************************************/

@if $push-has-action {
  .c-push--action {
    .c-push__title {
      @include h5;
    }
  }
}
