$input-height: 48px;
$input-padding-y: 12px;
$input-padding-x: 12px;
$input-foreground: var(--color-black);
$input-background: var(--color-true-white, var(--color-white));
$input-border-size: 1px;
$input-border-color: var(--color-primary-600) !default;
$input-border-radius: 4px;
$input-placeholder-color: var(--color-neutral-500);
$input-outline-size: 3px;
$input-icon-size: 18px;

$input-action-enable: true;
$input-action-size: $input-icon-size;

$input-select-icon: "<svg viewBox=\"0 0 18 18\" xmlns=\"http://www.w3.org/2000/svg\">\
  <path d=\"M5.33582 6.22119L8.77832 9.65619L12.2208 6.22119L13.2783 7.27869L8.77832 11.7787L4.27832 7.27869L5.33582 6.22119Z\" fill=\"%color%\" />\
</svg>";

@mixin input-outline {
  box-shadow: 0 0 0 rem($input-outline-size) var(--input-outline-color);
}
