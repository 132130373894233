/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Box-sizing
 * Type: Generic
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

* {
  box-sizing: border-box;
}
