/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Global
 * Type: Setting
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

/**
 * High level
*******************************************************************************/

$global-font-size: $font-size-regular;
$global-line-height-px: $font-size-regular * 1.5;
$global-line-height: math.div($global-line-height-px, $global-font-size);
$global-toaster-zindex: 30;
$global-header-zindex: 20;
$global-footer-zindex: 10;

/**
 * UI
*******************************************************************************/

$global-border-width: 1px;
$global-border-color: color("black");
$global-separator: 0.0625rem solid var(--color-border) !default;
