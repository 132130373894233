/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Grid
 * Type: Object
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$grid-gap: $spacing-regular;
$grid-min: 280px;
$grid-has-quirky: false !default;
$grid-has-gallery: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-grid {
  --gap: #{ rem($grid-gap) };
  --min: #{ rem($grid-min) };

  display: grid;
  padding: 0;
  margin: 0;
  list-style: none;
  grid-gap: var(--gap);

  @supports (width: min(var(--min), 100%)) {
    grid-template-columns: repeat(auto-fit, minmax(min(var(--min), 100%), 1fr));
  }

  > .o-grid__item {
    &--full {
      grid-column: 1 / -1;
    }
  }
}

/*******************************************************************************
 * Variant
*******************************************************************************/

.o-grid--large {
  --gap: #{ rem($spacing-regular) };

  @include mq($from: large) {
    --gap: #{ rem($spacing-large) };
  }
}

.o-grid--huge {
  --gap: #{ rem($spacing-large) };

  @include mq($from: large) {
    --gap: #{ rem($spacing-huge) };
  }
}

.o-grid--center {
  align-items: center;
}

.o-grid--stretch {
  > .o-grid__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      width: 100%;
      height: 100%;
    }
  }
}

@if ($grid-has-quirky) {
  @include mq($from: large) {
    .o-grid__item--quirky {
      margin-top: rem(150px);
    }
  }
}

@if ($grid-has-gallery) {
  .o-grid--gallery {
    --min: #{ fluid-size-value(150px, 240px) };
  }
}
