/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Global
 * Type: Utilities
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.u-framed {
  @include fluid-size(24px, 40px, padding);
  border: var(--separator);
  border-radius: rem(8px);
}

.u-framed-small {
  padding: rem(24px);
  border: var(--separator);
  border-radius: rem(8px);
}
