/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Photo
 * Type: Component
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$photo-font-size: $font-size-small;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-photo {
  position: relative;
  font-size: rem($photo-font-size);

  > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  > * + * {
    margin-top: rem($spacing-regular);
  }
}

  .c-photo__head {
    position: relative;
    line-height: 0;
  }

    .c-photo__figure {
      line-height: 0;
    }

    .c-photo__zoom {
      --background: var(--color-background);

      position: absolute;
      right: rem(16px);
      bottom: rem(-20px);
      border: 0;
      border-radius: 50%;
    }

    a.c-photo__zoom,
    button.c-photo__zoom {
      &:hover {
        --background: var(--color-primary-100);
      }
    }

  .c-photo__body {
    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: rem(8px);
    }
  }

    .c-photo__title {}

    .c-photo__richtext {
      display: block;
      display: -webkit-box;
      max-height: 4.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: var(--color-neutral-600);
    }
