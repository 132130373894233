/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Note
 * Type: Component
 * Description:
 *  An informative message indicating instructions to the user to paired with an
 *  input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$note-colors: (
  "help": color("neutral-500"),
  "info": color("primary-800"),
  "success": color("success-500"),
  "warning": color("warning-500"),
  "error": color("error-500"),
);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-note {
  display: flex;
  align-items: baseline;
  font-size: rem(14px);
}

  .c-form-note__icon {
    flex: 0 0 auto;
    margin-right: rem(8px);
    font-size: rem(16px);
  }

  .c-form-note__text {
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    font-size: rem(14px);
  }

/*******************************************************************************
 * Type modifiers
*******************************************************************************/

.c-form-note--info,
.c-form-note--success,
.c-form-note--warning,
.c-form-note--error {
  font-weight: $font-bold;
}

@each $type, $color in $note-colors {
  .c-form-note--#{$type} { color: $color; }
}
