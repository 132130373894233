/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Global
 * Type: Elements
**/

/*******************************************************************************
 * Variables
*******************************************************************************/

$global-scroll-margin: 0 !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

[id] {
  scroll-margin-top: rem($global-scroll-margin);
}

details {
  $size: 8px;

  > summary {
    font-weight: $font-bold;
    text-decoration: underline;
    cursor: pointer;

    &::-webkit-details-marker {
      display: none;
    }

    // Firefox
    &::marker {
      font-size: 0;
    }

    &::after {
      content: "";
      display: inline-block;
      width: em($size, $size);
      height: em($size, $size);
      margin-left: em($size, $size);
      font-size: em($size, $font-size-regular);
      vertical-align: 0.2em;
      border: rem(2px) solid transparent;
      border-right-color: currentcolor;
      border-bottom-color: currentcolor;
      border-radius: rem(1px);
      transform: rotate(0.125turn);
      transform-origin: 66% 66%;

      @include transition(transform);
    }

    &:hover {
      text-decoration: none;
    }
  }

  &[open] {
    > summary::after {
      transform: rotate(-0.375turn);
    }
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  details {
    break-inside: avoid;

    > summary::after {
      border-top-color: transparent !important;
      border-left-color: transparent !important;
    }
  }
}
