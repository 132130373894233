/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Figure
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Variables
*******************************************************************************/

$figure-font-size: $font-size-small;
$figure-legend: var(--color-black) !default;
$figure-has-full: false !default;
$figure-has-shadow: false !default;
$figure-has-magazine: false !default;

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-figure {
  display: inline-block;
  margin: 0;
  font-size: rem($figure-font-size);
}

  .c-figure__media {
    overflow: hidden;
    line-height: 0;

    svg {
      max-width: 100%;
      height: auto;
    }

    .c-figure--image & {
      max-width: max-content;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .c-figure__legend {
    margin-top: rem($spacing-small);
    color: $figure-legend;
  }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-figure--video {
  display: block;
  clear: both;

  .c-figure__media {
    position: relative;
    display: block;
    height: 0;
    padding-top: math.div(9, 16) * 100%;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      overflow: hidden;
    }
  }
}

@if $figure-has-full {
  .c-figure--full {
    display: block;

    .c-figure__media,
    picture {
      display: block;
      max-width: 100%;
    }

    .c-figure__media {
      img {
        width: 100%;
      }
    }
  }
}

@if $figure-has-shadow {
  .c-figure--shadow {
    .c-figure__media {
      @include shadow;
    }
  }
}

@if $figure-has-magazine {
  .c-figure--magazine {
    picture {
      display: flex;
      align-items: center;
      width: auto;
      aspect-ratio: 346 / 463;
    }
  }
}

/*******************************************************************************
 * Media Queries
*******************************************************************************/

@media print {
  .c-figure {
    break-inside: avoid;
  }
}
